import { Divider, Flex, Typography } from 'antd';
import { Article } from 'models/article';
import Tag from './Tag';

interface NewsItemProps {
  data: Article;
  onClick?: () => void;
}

const NewsItem: React.FC<NewsItemProps> = ({ data, onClick }) => {
  return (
    <>
      <Flex vertical onClick={onClick} className="px-4">
        <h3 className="text-lg">{data.title}</h3>
        <Typography.Paragraph className="text-base">
          {data.category}
        </Typography.Paragraph>
        <Tag createdAt={data.createdAt} />
      </Flex>
      <Divider />
    </>
  );
};

export default NewsItem;
