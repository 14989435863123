import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18n';
export enum ThemeModeColor {
  LIGHT = 'light',
  DARK = 'dark',
}
export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    mode: ThemeModeColor.LIGHT,
    siderCollasped: false,
    isSiderResponsive: false,
    lng: i18n.language,
  },
  reducers: {
    setThemeMode: (state, action) => {
      // Color Theme cho dark/light mode
      state.mode = action.payload;
    },
    setLng: (state, action) => {
      // Đổi ngôn ngữ
      state.lng = action.payload;
    },
    setSiderResponsive: (state, action) => {
      // tracking state khi sider trigger responsive
      state.isSiderResponsive = action.payload;
    },
    setSiderCollasped: (state, action) => {
      // tracking state khi sider đóng hoặc mở để style UI
      state.siderCollasped = action.payload;
    },
    setCollaspedSiderAfterClickMenu: (state) => {
      //Close sider sau khi click menu item
      if (state.isSiderResponsive && !state.siderCollasped)
        state.siderCollasped = true;
    },
  },
});

export const {
  setThemeMode,
  setSiderResponsive,
  setSiderCollasped,
  setCollaspedSiderAfterClickMenu,
  setLng,
} = themeSlice.actions;

export const selectThemeMode = (state) => state.theme.mode;
export const selectCurrentLng = (state) => state.theme.lng;
export const selectSiderCollasped = (state) => state.theme.siderCollasped;
export const selectSiderBreakpoint = (state) => state.theme.isSiderResponsive;
export const selectSiderResponsiveOpen = (state) =>
  state.theme.isSiderResponsive && !state.theme.siderCollasped;

export default themeSlice;
