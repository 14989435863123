import { useSearchCountry } from 'hooks/useMeilisearch';
import { SearchCountryItem } from 'models/meilisearch';
import { useEffect, useState } from 'react';
import { CiSearch } from 'react-icons/ci';

import { Flex, Input, List, Modal } from 'antd';

interface PhoneSelectProps {
  onCountryPhoneChange: (data: SearchCountryItem) => void;
}

const PhoneSelect: React.FC<PhoneSelectProps> = ({ onCountryPhoneChange }) => {
  const [selectedCountry, setSelectedCountry] = useState<SearchCountryItem>({
    name: 'Vietnam',
    phone: '+84',
    flag: '🇻🇳',
  });
  const [openSearchPopup, setOpenSearchPopup] = useState(false);
  const [textSearch, setTextSearch] = useState('');
  const { data: countries } = useSearchCountry(textSearch);

  const openSearchCountryModal = () => {
    setOpenSearchPopup(true);
  };

  useEffect(() => {
    onCountryPhoneChange(selectedCountry);
  }, [selectedCountry]);

  return (
    <>
      <div
        className="z-50 cursor-pointer absolute top-[12px] left-2 w-[55px] h-5 border-r-solid border-r-[1px] border-r-[#C5C5C5]"
        onClick={openSearchCountryModal}
      >
        <div className="center gap-1  base-hover-effect">
          <CiSearch className="w-5 h-5" />
          <span className="w-5 h-5 text-lg -translate-y-[3px]">
            {selectedCountry.flag}
          </span>
        </div>
      </div>

      <Modal
        open={openSearchPopup}
        onCancel={() => setOpenSearchPopup(false)}
        footer={() => null}
        className="min-w-fit"
      >
        <div className="h-fit max-h-[70vh] overflow-y-auto mt-6">
          <Input
            autoFocus
            name="searchCountry"
            type="text"
            placeholder=""
            prefix={<CiSearch />}
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
          />
          <Flex vertical className="p-1">
            <List
              header={null}
              footer={null}
              dataSource={countries}
              renderItem={(country) => (
                <List.Item
                  key={country.phone}
                  onClick={() => {
                    setSelectedCountry(country);
                    setOpenSearchPopup(false);
                  }}
                  className="!cursor-pointer hover:bg-[#F3F3F3] p-2 h-12"
                >
                  ({country.phone}) {country.flag} {country.name}
                </List.Item>
              )}
            />
          </Flex>
        </div>
      </Modal>
    </>
  );
};

export default PhoneSelect;
