import { MAX_UPLOAD_SIZE } from 'constants/common';

export const errors = {
  email: {
    invalid: 'Email không hợp lệ',
  },
  phone: {
    invalid: 'Số điện thoại không hợp lệ',
  },
  password: {
    invalid: 'Tối thiểu tám ký tự, ít nhất một chữ cái và một chữ số',
    not_match: 'Mật khẩu không khớp',
  },
  chatGroup: {
    maxSize: 'Tối đa 10 members',
  },
  common: {
    required: 'Trường thông tin bắt buộc',
    notExistUser: 'Tài khoản không tồn tại',
    INVALID_FILE: 'File không hợp lệ. Vui lòng kiểm tra lại.',
    OUT_OF_FILE_SIZE: `File vượt quá ${
      MAX_UPLOAD_SIZE / (1024 * 1024)
    }MB. Vui lòng kiểm tra lại.`,
    USER_INVALID:
      'Profile không hợp lệ. Bạn sẽ được chuyển trang trong vài giây...',
  },
};

export const messages = {
  UPLOAD_SUCCESS: 'Tải lên thành công',
  REMOVE_SUCCESS: 'Xoá thành công',
  REGISTER_SUCCESS: 'Đăng ký thành công',
  CREATE_SUCCESS: 'Tạo thành công',
  UPDATE_SUCCESS: 'Cập nhật thành công',
  UPDATE_FAILED: 'Cập nhật thất bại',
  APPROVED_DISPUTE: 'Phê duyệt thành công',
  REJECTED_DISPUTE: 'Từ chối thành công',
  SEND_SUCCESS: 'Gửi thành công',
};

export const tooltips = {
  PROCESSING_DENY: 'Một số tiến trình đang chạy. Vui lòng đợi hoàn tất!',
};
