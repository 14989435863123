import loadable from '@loadable/component';
import {
  AccountIcon,
  ChatIconSider,
  ComplainIcon,
  CreateTripIcon,
  FinanceIcon,
  FlagIcon,
  ListIcon,
  StatementIcon,
  StoresIcon,
  SupportIcon,
  ToolIcon,
  TripManagementIcon,
  UserTripIcon,
} from 'assets/icons';
import MenuItem from 'components/Layout/Sider/MenuItem';
import AppRoutes from 'constants/routes';
import { Navigate, Outlet } from 'react-router-dom';
import { AccountSetting } from 'sections/Account/AccountSetting';
import { DisputeProvider } from 'sections/Dispute/context';
import type { RouteItem } from './types';
const TripFormCreate = loadable(
  () => import('sections/TripManagement/TripFormCreate'),
);

const TripFormUpdate = loadable(
  () => import('sections/TripManagement/TripFormUpdate'),
);
const Dashboard = loadable(() => import('sections/Dashboard'));
const FinanceStatementPage = loadable(
  () => import('sections/Finance/Statement'),
);
const TripManagementList = loadable(
  () => import('sections/TripManagement/TripList'),
);
const ReviewList = loadable(() => import('sections/TripManagement/ReviewList'));

const TripSaleOrder = loadable(() => import('sections/Orders/TripSaleOrder'));
const DisputeOrder = loadable(() => import('sections/Orders/DisputeOrder'));
const QnAManagement = loadable(() => import('sections/TripManagement/qna'));
const DisputeOrderDetail = loadable(
  () => import('sections/Orders/DisputeOrderDetail'),
);
const ChatRoom = loadable(() => import('sections/Chat'));
const Support = loadable(() => import('sections/Support'));

const adminRoutes: RouteItem[] = [
  {
    path: '',
    element: <Navigate replace to={AppRoutes.DASHBOARD.index} />,
  },
  {
    path: AppRoutes.DASHBOARD.index,
    element: <Dashboard />,
    label: <MenuItem title={'dashboard'} to={AppRoutes.DASHBOARD.index} />,
    icon: <TripManagementIcon />,
  },
  {
    path: AppRoutes.TRIPMANAGEMENT.index,
    element: <Outlet />,
    label: <MenuItem title={'tripManagement.index'} />,
    icon: <FlagIcon />,
    children: [
      {
        path: AppRoutes.TRIPMANAGEMENT.list,
        element: <TripManagementList />,
        label: (
          <MenuItem
            to={AppRoutes.TRIPMANAGEMENT.list}
            title="tripManagement.list"
          />
        ),
        icon: <ListIcon />,
      },
      {
        path: AppRoutes.TRIPMANAGEMENT.reviews,
        element: <ReviewList />,
        label: (
          <MenuItem
            to={AppRoutes.TRIPMANAGEMENT.reviews}
            title="tripManagement.reviews"
          />
        ),
        icon: <UserTripIcon />,
      },
      {
        path: AppRoutes.TRIPMANAGEMENT.create,
        element: <TripFormCreate />,
        label: (
          <MenuItem
            to={AppRoutes.TRIPMANAGEMENT.create}
            title="tripManagement.create"
          />
        ),
        icon: <CreateTripIcon />,
      },
      {
        path: AppRoutes.TRIPMANAGEMENT.update,
        element: <TripFormUpdate />,
        hideFromMenu: true,
      },
      {
        path: AppRoutes.TRIPMANAGEMENT.qna,
        element: <QnAManagement />,
        label: (
          <MenuItem
            to={AppRoutes.TRIPMANAGEMENT.qna}
            title="tripManagement.qna"
          />
        ),
        icon: <UserTripIcon />,
      },
    ],
  },
  {
    label: <MenuItem title={'order.index'} />,
    path: AppRoutes.ORDER.index,
    element: <Outlet />,
    icon: <StoresIcon />,
    children: [
      {
        path: AppRoutes.ORDER.tripsaleform,
        element: <TripSaleOrder />,
        label: (
          <MenuItem
            to={AppRoutes.ORDER.tripsaleform}
            title="order.tripsaleform"
          />
        ),
        icon: <UserTripIcon />,
      },
      {
        path: AppRoutes.ORDER.dispute,
        element: <DisputeOrder />,
        label: <MenuItem to={AppRoutes.ORDER.dispute} title="order.dispute" />,
        icon: <ComplainIcon />,
      },
      {
        path: AppRoutes.ORDER.disputeDetail,
        element: (
          <DisputeProvider>
            <DisputeOrderDetail />
          </DisputeProvider>
        ),
      },
    ],
  },
  {
    path: AppRoutes.FINANCE.index,
    element: <Outlet />,
    label: <MenuItem title={'finance.index'} />,
    icon: <FinanceIcon />,
    children: [
      {
        label: (
          <MenuItem
            to={AppRoutes.FINANCE.statement}
            title="finance.statement"
          />
        ),
        path: AppRoutes.FINANCE.statement,
        icon: <StatementIcon />,
        element: <FinanceStatementPage />,
      },
    ],
  },
  {
    label: <MenuItem title={'account.index'} />,
    path: AppRoutes.ACCOUNT.index,
    element: <Outlet />,
    icon: <AccountIcon />,
    children: [
      {
        label: (
          <MenuItem to={AppRoutes.ACCOUNT.setting} title="account.setting" />
        ),
        icon: <ComplainIcon />,
        path: AppRoutes.ACCOUNT.setting,
        element: <AccountSetting />,
      },
    ],
  },
  {
    path: AppRoutes.CHAT.index,
    element: <ChatRoom />,
    label: <MenuItem to={AppRoutes.CHAT.index} title={'chat.index'} />,
    icon: <ChatIconSider />,
  },
  {
    path: AppRoutes.SUPPORT.index,
    element: <Support />,
    label: <MenuItem title={'support.index'} to={AppRoutes.SUPPORT.index} />,
    icon: <SupportIcon />,
  },
  {
    path: AppRoutes.TOOLS.index,
    label: <MenuItem title={'tools.index'} />,
    icon: <ToolIcon />,
    children: [
      {
        path: AppRoutes.TOOLS.shortenUrl,
        element: <></>,
        label: (
          <MenuItem
            toNew={AppRoutes.TOOLS.shortenUrl}
            title="tools.ShortenUrl"
          />
        ),
      },
      {
        path: AppRoutes.TOOLS.map,
        label: <MenuItem toNew={AppRoutes.TOOLS.map} title="tools.Map" />,
      },
      {
        path: AppRoutes.TOOLS.translateAI,
        label: (
          <MenuItem
            toNew={AppRoutes.TOOLS.translateAI}
            title="tools.TranslateAI"
          />
        ),
      },
      {
        path: AppRoutes.TOOLS.imageAI,
        label: (
          <MenuItem toNew={AppRoutes.TOOLS.imageAI} title="tools.ImageAI" />
        ),
      },
    ],
  },
];

export default adminRoutes;
