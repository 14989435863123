import Common from './common';
import Sider from './sider';
import Validation from './validation';

const en = {
  Common,
  Sider,
  Validation,
};

export default en;
