import { Outlet } from 'react-router-dom';
import { ConfigProvider, App as AppAntd } from 'antd';
import { registerChartJs } from 'utils/register-chart';
import { useAppSelector } from 'hooks/useReduxStore';
import { selectCurrentLng } from 'stores/themeSlice';
import { LANGUAGE_TEXT } from 'i18n';
import ThemeProvider from 'styles/ant-theme';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as moment from 'moment';

registerChartJs();

function App() {
  const lng = useAppSelector(selectCurrentLng);
  moment.locale(LANGUAGE_TEXT[lng].value);
  return (
    <ConfigProvider locale={LANGUAGE_TEXT[lng].antd}>
      <ThemeProvider>
        <AppAntd>
          <Outlet />
        </AppAntd>
      </ThemeProvider>
      <ReactQueryDevtools />
    </ConfigProvider>
  );
}

export default App;
