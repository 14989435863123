import { useEffect } from 'react';
import {
  ThemeModeColor,
  selectThemeMode,
  setThemeMode,
} from 'stores/themeSlice';
import { useAppDispatch, useAppSelector } from './useReduxStore';

const useSwitchThemeMode = () => {
  const dispatch = useAppDispatch();
  const themeMode = useAppSelector(selectThemeMode);
  const element = document.documentElement;

  useEffect(() => {
    switch (themeMode) {
      case 'dark':
        element.setAttribute('data-theme', ThemeModeColor.DARK);
        break;
      default:
        element.setAttribute('data-theme', ThemeModeColor.LIGHT);
        break;
    }
  }, [themeMode]);

  const switchThemeMode = (mode) => {
    dispatch(setThemeMode(mode));
  };

  return {
    themeMode,
    switchThemeMode,
    ThemeModeColor,
  };
};

export default useSwitchThemeMode;
