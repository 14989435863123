import type { CheckboxProps, FormItemProps } from 'antd';
import { Checkbox, Form } from 'antd';
import type { ReactNode } from 'react';
import { useId } from 'react';

import HelperText from '../HelperText';
import { useField } from 'formik';

export interface CheckboxInputProps extends CheckboxProps {
  name: string;
  label?: string | ReactNode;
  className?: string;
  formItemProps?: FormItemProps;
  required?: boolean;
  helperText?: string;
  isErr?: boolean;
  errMess?: string;
}

const CheckboxInput = ({
  label,
  className = '',
  name,
  formItemProps,
  required,
  helperText,
  isErr,
  errMess,
  ...props
}: CheckboxInputProps) => {
  const id = useId();
  const [field, meta] = useField({
    name,
  });
  return (
    <Form.Item
      className={className}
      colon={false}
      help={
        <HelperText
          error={isErr ? meta.error : ''}
          helperText={helperText}
          value={field.value}
        />
      }
      htmlFor={id}
      required={required}
      validateStatus={isErr ? 'error' : 'success'}
      {...formItemProps}
    >
      <Checkbox id={id} checked={field.value} {...field} {...props}>
        {label}
      </Checkbox>
    </Form.Item>
  );
};

export default CheckboxInput;
