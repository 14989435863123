import { useAppDispatch } from 'hooks/useReduxStore';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, To } from 'react-router-dom';
import { setCollaspedSiderAfterClickMenu } from 'stores/themeSlice';

interface MenuItemProps {
  to?: To;
  toNew?: string;
  title: string;
}

const MenuItem: React.FC<MenuItemProps> = ({ to, title, toNew }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  if (toNew) {
    return (
      <a
        href={toNew}
        target="_blank"
        onClick={() => dispatch(setCollaspedSiderAfterClickMenu())}
        className="font-medium"
      >
        {t(`Sider:${title}`)}
      </a>
    );
  }
  if (to) {
    return (
      <Link
        to={to || ''}
        onClick={() => dispatch(setCollaspedSiderAfterClickMenu())}
        className="font-medium"
      >
        {t(`Sider:${title}`)}
      </Link>
    );
  }
  return <div className="font-medium"> {t(`Sider:${title}`)} </div>;
};

export default MenuItem;
