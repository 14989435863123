import { Layout, Menu, MenuProps, SiderProps } from 'antd';
import SwitchLang from 'components/SwitchLang';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxStore';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fallBackActiveMenuPath, sideMenuRoutes } from 'router';
import {
  selectSiderCollasped,
  setSiderCollasped,
  setSiderResponsive,
} from 'stores/themeSlice';
import { SIDER_WIDTH } from 'styles/ant-theme';

const { Sider } = Layout;

export const CustomSider: React.FC<SiderProps> = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const collapsed = useAppSelector(selectSiderCollasped);
  const menuKey = '/' + location.pathname.split('/')[1] || '';
  const subMenuKey = fallBackActiveMenuPath(menuKey, location.pathname);
  const [stateOpenKeys, setStateOpenKeys] = useState([menuKey, subMenuKey]);

  const onOpenChange: MenuProps['onOpenChange'] = (openKeys) => {
    console.log('openKeys', openKeys);
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1,
    );
    // open
    if (currentOpenKey !== undefined) {
      setStateOpenKeys([currentOpenKey]);
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  return (
    <Sider
      width={SIDER_WIDTH}
      breakpoint="md"
      collapsedWidth="0"
      collapsible
      collapsed={collapsed}
      onBreakpoint={(broken) => {
        dispatch(setSiderResponsive(broken));
      }}
      onCollapse={(collapsed) => {
        dispatch(setSiderCollasped(collapsed));
      }}
    >
      <div className="mb-20">
        <div className="px-7 py-12">
          <img src={'/static/images/logo.png'} alt="logo" />
        </div>
        <Menu
          mode="inline"
          items={sideMenuRoutes}
          openKeys={stateOpenKeys}
          onOpenChange={onOpenChange}
          selectedKeys={[menuKey, subMenuKey]}
        />
      </div>
      <SwitchLang />
    </Sider>
  );
};
