import { SimpleUser, UpdateMyProfileDto } from 'models/user';
import { api } from '.';

export const getUserById = (uid: string) => {
  return api.get(`/users/find/by-id/${uid}`) as Promise<SimpleUser>;
};

export const getUserByEmailOrPhone = ({
  email,
  phone,
}: {
  email?: string;
  phone?: string;
}) => {
  return api.get(
    `/users/profile/uname/${email || phone}/public`,
  ) as Promise<SimpleUser>;
};

export const getMyProfile = () => {
  return api.get('/users/profile') as Promise<SimpleUser>;
};

export const updateMyProfile = (dto: UpdateMyProfileDto) => {
  return api.patch('/users/profile', dto);
};

export const changeMyPassword = (oldPwd: string, newPwd: string) => {
  return api.patch('/users/password', {
    oldPassword: oldPwd,
    newPassword: newPwd,
  });
};
