import { createBrowserRouter } from 'react-router-dom';

import loadable from '@loadable/component';
import App from 'App';
import Layout from 'components/Layout';
import AuthLayout from 'components/Layout/AuthLayout';
import { ErrorCodePage } from 'constants/common';
import AppRoutes from 'constants/routes';
import PrivateRoute from 'router/PrivateRoute';
import ErrorBoundary from 'sections/ErrorBoundary';
import adminRoutes from './adminRoutes';
import type { RouteItem } from './types';

const Login = loadable(() => import('sections/Login'));
const Logout = loadable(() => import('sections/Logout'));
const SocialProvider = loadable(() => import('sections/Provider'));

export const indexRoutes: RouteItem[] = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        errorElement: <ErrorBoundary code={ErrorCodePage.NOT_FOUND} />,
        element: (
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        ),
        children: adminRoutes,
      },
      {
        path: AppRoutes.SIGN_IN,
        element: (
          <AuthLayout>
            <Login />
          </AuthLayout>
        ),
      },
      {
        path: ':provider/redirect',
        element: <SocialProvider />,
      },
      {
        path: AppRoutes.LOGOUT,
        element: <Logout />,
      },
      { path: '*', element: <ErrorBoundary code={ErrorCodePage.NOT_FOUND} /> },
    ],
  },
];

const routes = [...indexRoutes, ...adminRoutes];

//Export ra menu cho sider
export const convertToMenuRoutes = (routeArr: RouteItem[]) => {
  return routeArr
    .map((route) => ({
      ...route,
      ...(Array.isArray(route.children)
        ? {
            children: convertToMenuRoutes(
              route.children.filter((route: RouteItem) => route?.label),
            ),
          }
        : {}),
      key: route.path,
    }))
    .filter((route) => route.label);
};

export const sideMenuRoutes = convertToMenuRoutes(adminRoutes);

// Case không hightlight submenu khi chọn edit trip vì không xuất hiện trên menu => fallback hightlight create trip
export const fallBackActiveMenuPath = (menuKey, subMenuKey) => {
  if (menuKey === AppRoutes.TRIPMANAGEMENT.index) {
    // đang là trang update thì trả về path của create
    if (subMenuKey.includes('/update')) {
      return AppRoutes.TRIPMANAGEMENT.create;
    } else return subMenuKey;
  }
  // các case còn lại ko fallback
  return subMenuKey;
};
export const router = createBrowserRouter(routes);
