import { RcFile } from 'antd/lib/upload';
import axios from 'axios';
import { api } from '../libs/api/index';
import {
  DEFAULT_IMAGE_EXT,
  MAX_UPLOAD_SIZE,
  MEDIA_MIME_TYPES,
} from 'constants/common';
import env from 'constants/env';
import { MODAL_CONFIRM_CLOSE_BTN_ONLY_OPTIONS } from 'components/CustomAntd/Configs/modal';

interface PresignResponse {
  main: string;
  thumb: string;
  upload: string;
}

export const uploadFile = async (file: File, id: string, prefix: string) => {
  const isUploadFromLibrary = Boolean(file.name);
  const fileSplits = isUploadFromLibrary
    ? file.name.toLowerCase().split('.')
    : null;

  // Generate presigned url
  const presignedUrl = (await api.post(
    '',
    {
      prefix,
      id,
    },
    {
      baseURL: env.generatePresignedUrl,
    },
  )) as PresignResponse;
  const { main, thumb, upload } = presignedUrl;

  // Upload file with presigned url
  const fileExt = (
    isUploadFromLibrary && fileSplits
      ? fileSplits[fileSplits.length - 1]
      : DEFAULT_IMAGE_EXT
  ) as keyof typeof MEDIA_MIME_TYPES;

  await axios.put(upload, file, {
    headers: {
      'Content-Type': MEDIA_MIME_TYPES[fileExt],
    },
    withCredentials: false,
  });

  return {
    main,
    thumb,
    uid: id,
  };
};

export const beforeUploadImage = (file: File, modal) => {
  let fileError = {};

  const isJpgOrPng =
    file.type && Object.values(MEDIA_MIME_TYPES).includes(file.type);
  if (!isJpgOrPng) {
    const msg = `Định dạng file phải là ${Object.keys(MEDIA_MIME_TYPES).join(
      '/',
    )}`;
    modal.confirm({
      ...MODAL_CONFIRM_CLOSE_BTN_ONLY_OPTIONS,
      content: msg,
    });
    fileError = {
      code: 'FileInvalidType',
      message: msg,
    };
    return fileError;
  }
  const isLt2M = file.size < MAX_UPLOAD_SIZE;
  if (!isLt2M) {
    const msg = `Kích thước ảnh lớn hơn ${MAX_UPLOAD_SIZE / 1024 / 1024}MB`;
    modal.confirm({
      ...MODAL_CONFIRM_CLOSE_BTN_ONLY_OPTIONS,
      content: msg,
    });
    fileError = {
      code: 'FileTooLarge',
      message: msg,
    };
    return fileError;
  }
  return null;
};

export const getBase64 = (file: RcFile): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};
