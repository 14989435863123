import { Modal } from 'antd';
import { Article } from 'models/article';

interface NewDetailModalProps {
  open: boolean;
  data?: Article;
  onCancel: () => void;
}

const NewDetailModal: React.FC<NewDetailModalProps> = ({
  open,
  data,
  onCancel,
}) => {
  return (
    <Modal
      title={data?.title}
      open={open}
      onOk={onCancel}
      centered
      onCancel={onCancel}
      className="min-w-fit"
      destroyOnClose={false}
    >
      <div
        className="h-fit max-h-[70vh] overflow-auto max-w-[1440px]"
        dangerouslySetInnerHTML={{
          __html: data?.content || 'No content',
        }}
      />
    </Modal>
  );
};

export default NewDetailModal;
