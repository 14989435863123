import { arrayMoveImmutable } from 'array-move';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CheckpointNameType } from 'constants/common';
import { LngLat } from 'maplibre-gl';
import { CheckpointFormData } from 'models/trip';
import { v4 as uuid } from 'uuid';

// Define a type for the slice state
export interface CheckpointState {
  points: CheckpointFormData[];
  isOperating: boolean;
  dataForm: CheckpointFormData;
  addPointSuccess: boolean;
  mapView: {
    center?: LngLat;
    zoom?: number;
  };
}

// Define the initial state using that type
export const initialState: CheckpointState = {
  points: [],
  isOperating: false,
  dataForm: {
    _id: uuid(),
    checkpointName: CheckpointNameType.Start,
    date: '1',
    address: '',
    checkpointType: '',
    time: '',
    images: [],
    content: '',
    coor: {
      lat: 0,
      lng: 0,
    },
  },
  addPointSuccess: false,
  mapView: {},
};

export const checkpointSlice = createSlice({
  name: 'checkpoints',
  initialState,
  reducers: {
    setPoints(state, action: PayloadAction<CheckpointFormData[]>) {
      state.points = action.payload;
    },
    beforeAddPoint(state, action) {
      const itemIndex = action.payload;

      // "Thêm Trạm +" case
      if (itemIndex === 0 && itemIndex === state.points.length) {
        // nothing to do
      } else if (itemIndex === state.points.length && itemIndex - 1 > 0) {
        state.points[itemIndex - 1].checkpointName = `Trạm ${itemIndex - 1}`;
      } else {
        // Remaining cases
        for (let i = itemIndex; i < state.points.length; i++) {
          state.points[i].checkpointName =
            i === state.points.length - 1
              ? CheckpointNameType.End
              : `Trạm ${i + 1}`;
        }
      }
    },
    cancelAddPoint(state) {
      for (let i = 0; i < state.points.length; i++) {
        state.points[i].checkpointName =
          i === 0
            ? CheckpointNameType.Start
            : i === state.points.length - 1
            ? CheckpointNameType.End
            : `Trạm ${i}`;
      }
    },
    setIsOperating(state, action: PayloadAction<boolean>) {
      state.isOperating = action.payload;
    },
    setDataForm(
      state,
      action: PayloadAction<{
        [key in keyof CheckpointFormData]?: CheckpointFormData[key];
      }>,
    ) {
      state.dataForm = {
        ...state.dataForm,
        ...action.payload,
      };
    },
    resetDataForm(state) {
      state.dataForm = {
        ...initialState.dataForm,
      };
    },
    addPoint(
      state,
      action: PayloadAction<{ data: CheckpointFormData; index: number }>,
    ) {
      const { data, index } = action.payload;
      const newPoints = [...state.points];

      switch (index) {
        case 0:
          newPoints.unshift({
            ...data,
            createRoute: true,
            _id: uuid(),
          });

          break;
        case state.points.length:
          newPoints.push({
            ...data,
            createRoute: true,
            _id: uuid(),
          });
          break;
        default:
          newPoints.splice(index, 0, {
            ...data,
            createRoute: true,
            _id: uuid(),
          });
          break;
      }
      state.points = newPoints;
      state.addPointSuccess = true;
    },
    updateSpecificPoint(
      state,
      action: PayloadAction<{ dataForm: CheckpointFormData; index: number }>,
    ) {
      const { dataForm, index } = action.payload;
      state.points[index] = dataForm;
    },
    removePoint(state, action: PayloadAction<string>) {
      const index = state.points.findIndex((poi) => poi._id === action.payload);
      if (index > -1) {
        state.points.splice(index, 1);
        for (let i = 0; i < state.points.length; i++) {
          state.points[i].checkpointName =
            i === 0
              ? CheckpointNameType.Start
              : i === state.points.length - 1
              ? CheckpointNameType.End
              : `Trạm ${i}`;
        }
      }
    },
    setAddPointSuccess(state, action: PayloadAction<boolean>) {
      state.addPointSuccess = action.payload;
    },
    setGuides(
      state,
      action: PayloadAction<{ guideSerial: number; value: boolean }>,
    ) {
      state.points[action.payload.guideSerial].createRoute =
        action.payload.value;
    },
    setViewMap(
      state,
      action: PayloadAction<{
        center?: LngLat;
        zoom?: number;
      }>,
    ) {
      state.mapView = action.payload;
    },
    setCheckpointNewIndex(
      state,
      action: PayloadAction<{ oldIndex: number; newIndex: number }>,
    ) {
      const { oldIndex, newIndex } = action.payload;
      if (oldIndex === newIndex) return;

      const pointsTemp = [...state.points];

      const sortedPoints = arrayMoveImmutable(pointsTemp, oldIndex, newIndex);

      const newPoints = sortedPoints.map((point, index) => ({
        ...point,
        checkpointName:
          index === 0
            ? CheckpointNameType.Start
            : index === sortedPoints?.length - 1
            ? CheckpointNameType.End
            : `Trạm ${index}`,
      }));

      return {
        ...state,
        points: newPoints,
      };
    },
  },
});

export const { actions } = checkpointSlice;

export default checkpointSlice;
