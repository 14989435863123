import { SearchCityItem } from 'models/meilisearch';

import { meilisearchClient } from '.';

const countryIndex = meilisearchClient.index('cities');

export const searchCity = async (text: string) => {
  try {
    const res = await countryIndex.search(text || '');
    return res.hits as SearchCityItem[];
  } catch (error) {
    return [];
  }
};
