import { Flex, Tag } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
interface TagProps {
  createdAt: string;
}

const NewTag: React.FC<TagProps> = ({ createdAt }) => {
  const { t } = useTranslation();
  const createdAtMoment = moment.utc(createdAt).local();
  const now = moment();
  const daysDiff = now.diff(createdAtMoment, 'days');
  // 3 ngày gần nhất thì tag Mới
  return (
    <Flex align="center">
      {daysDiff <= 3 && <Tag color="orange">{t('Common:new')}</Tag>}
      <div>{createdAtMoment.format('Do MMMM YYYY')}</div>
    </Flex>
  );
};

export default NewTag;
