import { App, Avatar, Flex, Layout, Switch, Typography } from 'antd';
import { SettingMenuIcon } from 'assets/icons';
import NoAvatar from 'assets/images/no-avatar2.png';
import { MODAL_CONFIRM_DEFAULT_OPTIONS } from 'components/CustomAntd/Configs/modal';
import AppRoutes from 'constants/routes';
import { useAppSelector } from 'hooks/useReduxStore';
import useSwitchThemeMode from 'hooks/useSwitchThemeMode';
import { Link, useNavigate } from 'react-router-dom';
import { selectProfile } from 'stores/selectors';
import { selectSiderResponsiveOpen } from 'stores/themeSlice';
import { formatRole } from 'utils/format';
const { Header: AntdHeader } = Layout;

export const Header = () => {
  const navigate = useNavigate();
  const { modal } = App.useApp();
  const user = useAppSelector(selectProfile);
  const isSiderResponsiveOpen = useAppSelector(selectSiderResponsiveOpen);
  const { switchThemeMode, ThemeModeColor, themeMode } = useSwitchThemeMode();
  const onChange = (checked: boolean) => {
    switchThemeMode(checked ? ThemeModeColor.LIGHT : ThemeModeColor.DARK);
  };

  return (
    <AntdHeader
      className="z-50"
      style={{
        boxShadow: '0px 8px 8px rgba(101, 109, 120, 0.15)',
      }}
    >
      <Flex justify="end" align="center" gap={32} style={{ height: '100%' }}>
        {!isSiderResponsiveOpen && (
          <>
            {/* Dark Mode toggle */}
            <Switch
              defaultChecked={themeMode === ThemeModeColor.LIGHT ? true : false}
              onChange={onChange}
              checkedChildren="🌞"
              unCheckedChildren="🌚"
            />
            {/* Setting icon */}
            <Link to={AppRoutes.ACCOUNT.setting} className="leading-3">
              <SettingMenuIcon />
            </Link>
          </>
        )}

        {user ? (
          <>
            {!isSiderResponsiveOpen && (
              <span className="font-bold text-[#5A9843] text-sm md:text-base">
                {formatRole(user?.roles)}
              </span>
            )}
            <Flex align="center" gap={4}>
              <Avatar src={user?.avatar ? user?.avatar : NoAvatar} size={24} />
              <div
                className="cursor-pointer"
                onClick={() =>
                  modal.confirm({
                    ...MODAL_CONFIRM_DEFAULT_OPTIONS,
                    onOk: () => navigate(AppRoutes.LOGOUT),
                    content: (
                      <Flex align="center" justify="center">
                        Bạn có muốn logout?
                      </Flex>
                    ),
                  })
                }
              >
                <Typography.Text>Logout</Typography.Text>
              </div>
            </Flex>
          </>
        ) : (
          <Flex>
            <Link to={AppRoutes.SIGN_IN}>Login</Link>
            <div style={{ borderRight: '1px solid #333333', height: 20 }} />
            <Link to="/complete-register">Register</Link>
          </Flex>
        )}
      </Flex>
    </AntdHeader>
  );
};
