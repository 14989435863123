export const GLOBAL_COLOR = {
  ORANGE_400: '#FF6C00',
  ORANGE_LOW: '#FF9D42',
  ORANGE_LIGHT: '#FAEFE6',

  GREY_GREY: '#EDEDED',
  GREY_BLUE: '#637381',
  GREY_100: '#F7F9FA',
  GREY_200: '#C5C5C5',
  GREY_300: '#CFD9E0',
  GREY_400: '#AEC1CC',
  GREY_500: '#9D9D9D',
  GREY_600: '#5A657C',
  GREY_700: '#7E7E7E',
  GREY_800: '#333',
  GREY_900: '#161C24',

  WHITE: '#FFF',
  WHITE_LIGHT: '#F5F5F5',
  WHITE_OPACITY_025: '#00000040',
  WHITE_OPACITY_04: '#0000000a',

  GRAY_200: '#E7EBEE',
  GRAY_600: '#7A7B7A',

  BLACK: '#000',
  BLACK_BUTTON: '#333333',
  SHADOW_ORANGE: '0px 0px 0px 3px rgba(255, 108, 0, 0.1)',

  //Github color palleted
  //https://raw.githubusercontent.com/wojciechkepka/vim-github-dark/master/assets/colors.png
  DARK: '#0d1117',
  DARK_100: '#161b22',
  DARK_200: '#21262d',
  DARK_300: '#89929b',
  DARK_BLUE: '#111B2B',

  DIVIDER_WHITE: '#0000001a',
  DIVIDER_DARK: '#30363d',
};
