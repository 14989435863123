import env from 'constants/env';

const AppRoutes = {
  ROOT: '/',
  SIGN_IN: '/login',
  SIGN_UP: '/sign-up',
  LOGOUT: '/logout',
  SOCIAL_PROVIDER: '/:provider/redirect',
  DASHBOARD: {
    index: '/dashboard',
  },
  TRIPMANAGEMENT: {
    index: '/tripmanagement',
    list: '/tripmanagement/list',
    create: '/tripmanagement/create',
    update: '/tripmanagement/update/:tripId',
    reviews: '/tripmanagement/reviews',
    turnover: '/tripmanagement/turnover',
    statement: '/tripmanagement/statement',
    qna: '/tripmanagement/qna',
  },
  ORDER: {
    index: '/order',
    dispute: '/order/dispute',
    disputeDetail: '/order/dispute/:id',
    tripsaleform: '/order/tripsaleform',
  },
  FINANCE: {
    index: '/finances',
    turnover: '/finances/turnover',
    statement: '/finances/statement',
  },
  ACCOUNT: {
    index: '/account',
    setting: '/account/setting',
  },
  CHAT: {
    index: '/chat',
    setting: '/chat/setting',
  },
  SUPPORT: {
    index: '/support',
    popularquestion: '/support/popular-question',
    disputeticket: '/support/dispute-ticket',
  },
  TOOLS: {
    index: '/tool',
    shortenUrl: env.shortenUrlTool,
    map: env.mapTool,
    translateAI: env.translateAITool,
    imageAI: env.imageAITool,
  },
};

export const PageNameList = [
  {
    path: AppRoutes.DASHBOARD.index,
    name: 'Dashboard',
  },
  {
    path: AppRoutes.TRIPMANAGEMENT.list,
    name: 'Danh sách trip',
  },
  {
    path: AppRoutes.TRIPMANAGEMENT.create,
    name: 'Tạo trip',
  },
  {
    path: AppRoutes.TRIPMANAGEMENT.update,
    name: 'Cập nhật trip',
  },
  {
    path: AppRoutes.TRIPMANAGEMENT.reviews,
    name: 'Danh sách đánh giá',
  },
  {
    path: AppRoutes.TRIPMANAGEMENT.qna,
    name: 'Quản lý hỏi đáp',
  },
  {
    path: AppRoutes.ORDER.tripsaleform,
    name: 'Đơn bán trip',
  },
  {
    path: AppRoutes.ORDER.dispute,
    name: 'Khiếu nại',
  },
  {
    path: AppRoutes.FINANCE.statement,
    name: 'Sao kê',
  },
  {
    path: AppRoutes.ACCOUNT.setting,
    name: 'Cài đặt',
  },
  {
    path: AppRoutes.CHAT.index,
    name: 'Chat',
  },
  {
    path: AppRoutes.SUPPORT.index,
    name: 'Hỗ trợ',
  },
  {
    path: AppRoutes.TOOLS.index,
    name: 'Công cụ',
  },
];

export default AppRoutes;
