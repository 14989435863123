import { Divider, Flex, Image, Typography } from 'antd';
import TripicalLogo from 'assets/images/logo.png';

export default function AuthLayout({ children }: { children: JSX.Element }) {
  return (
    <Flex align="center" justify="center" className="min-h-screen">
      <Flex
        align="center"
        justify="center"
        gap={2}
        className="fixed top-6 left-8"
      >
        <Image
          src={TripicalLogo}
          alt=""
          width={147}
          height={46}
          preview={false}
        />
        <Divider type="vertical" className="h-11 border-grey" />
        {/* chữa cháy bằng mt-3 */}
        <Typography.Title className="mt-3" level={2}>
          Trip Maker Center
        </Typography.Title>
      </Flex>
      {children}
    </Flex>
  );
}
