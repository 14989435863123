const Common = {
  // Global
  yes: 'Yes',
  no: 'Skip',
  cancel: 'Cancel',
  ok: 'OK',
  apply: 'Apply',
  send: 'Send',
  empty: 'Empty',
  search: 'Search',
  error: 'Error',
  done: 'Done',
  sortTrips: 'Sorting checkpoints',
  new: 'New',
  check: 'Check',
  // Auth
  signIn: 'Sign In',
  signUp: 'Sign Up',
  notHaveAccount: 'Don’t have an account?',
  haveAccount: 'Already have an account?',
  // Authorization
  accessDeniedCode: '403',
  accessDeniedDesc: 'Sorry, you are not allowed to access this page.',
  notFoundCode: '404',
  notFoundDesc: 'Sorry, the page you are looking for does not exist.',
  // Button
  submit: 'Submit',
  backHome: 'Back to Home',
  // Page Titles
  dashboard: {
    welcomeMessage:
      'Welcome to <strong>Tripical - Trip maker Center</strong>! Build your travel plans here.',
    statistics: 'Statistics',
    last7Days: 'Last 7 Days',
    lastMonth: 'Last Month',
  },
  news: {
    title: 'News',
  },
  account: {
    title: 'Account',
    setting: 'Settings',
  },
  finance: {
    title: 'Finance',
    turnover: 'Turnover',
    statement: 'Statement',
  },
  orders: {
    title: 'Orders',
    tripsaleform: 'Trip Sale Form',
    dispute: 'Dispute',
  },
  tripManagement: {
    title: 'Trip Management',
    list: 'List of Trips',
    create: 'Create Trip',
    update: 'Update Trip',
    reviews: 'List of Reviews',
    qnaTitle: 'QnA Management',
    qnaColumn: 'Trip Management',
    qnaTab: 'Question and Answer',
  },
  tripList: {
    trip: 'Trip',
    price: 'Price',
    status: 'Status',
    display: 'Display',
    action: 'Action',
    skuName: 'SKU Name',
    tripName: 'Trip Name',
    enterSku: 'Enter SKU',
    enterTripName: 'Enter Trip Name',
    selectCategory: 'Select Category',
    myTrips: 'My Trips',
    pendingApproval: 'Pending Approval',
    published: 'Published',
    tripLimit: 'Trip Posting Limit (Pending Approval + Published)',
    remainingListings: 'Remaining Listings',
  },
  createTrip: {
    marqueeIntro:
      'Travel itineraries  is not just a story or review! {{name}}, let’s make a difference with Tripical! Learn more',
    instructText: 'Outlines to building a trip.',
    categoryList: 'Category List',
    destination: 'Destination',
    districtTownLevel: 'District/Town Level',
    shortIntroduction:
      'A short introduction to this itinerary. Start each line with a bullet point.',
    tripIntroductionTitle: 'Trip Introduction',
    includesAllDesignCosts: 'Includes all the design costs in the itinerary.',
    upload: 'Upload',
    prominentImages: 'Featured Images',
    dragDropImageHere: 'Drag and drop images here or',
    uploadFromYourDevice: 'from your device',
    atLeastFourImages:
      'At least 4 images with a minimum resolution of 800x800 pixels.',
    egTickets:
      'Example: Tickets for trains, cars, boats... that need to be booked in advance. Pre-booking phone number / price list. Max 400 characters.',
    egSightseeingTickets:
      'Example: Sightseeing tickets that need to be booked in advance. Or price list. Max 400 characters.',
    accommodationDiningNotes:
      'Notes on accommodation/dining to be booked in advance if necessary. Max 600 characters.',
    tripName: 'Trip Name',
    shortDescription: 'Short Description',
    tripIntroduction:
      'Trip Introduction (Displayed to users before purchase & for SEO)',
    modeOfTransportation: 'Mode of Transportation',
    estimatedBudget: 'Estimated Budget',
    selectTool: 'Select Tool',
    estimatedPerson: 'Estimated per person.',
    prepareContentCheck:
      'Check the content to prepare before the trip with Mon AI assistant, results are for reference only',
    prepareBeforeTrip: 'To prepare before the trip',
    transportation: 'Transportation',
    sightseeing: 'Sightseeing',
    accommodationDining: 'Accommodation/Dining',
    essentialsToBring: 'Essentials to Bring',
    stationDetails: 'Station Details',
    preview: 'Preview',
    saveTrip: 'Save Trip',
    unsavedChanges:
      'Your changes have not been saved. Please press Save to continue.',
    enterContent: 'Enter Content',
    visitErr1: 'Please enter the location name',
    visitErr2: 'Please enter drop pin on maps',
  },
  checkpoint: {
    stationCount: 'Station Count',
    stationType: 'Station Type',
    date: 'Date',
    time: 'Time',
    stationName: 'Station Name',
    mapLocation: 'Map Location',
    maxCharacters: 'Maximum Characters:',
    recommendedWords: 'Recommended Words:',
    cancelChanges: 'Cancel Changes',
    saveTemporary: 'Save Temporarily',
    saveTemporaryOnly: 'Only save temporarily (do not update to the system)',
    addCheckpoint: 'Add Checkpoint',
    startLocation: 'Start Location',
    endLocation: 'End Location',
    location: 'Location',
    uploadImage: 'Upload Image',
    maxCharacters40: 'Up to 40 characters',
    createRoute: 'Create Route',
  },
  support: {
    title: 'Support',
    popularquestion: 'Popular Question',
    disputeticket: 'Dispute Ticket',
  },
  chat: {
    title: 'Chat',
    detail: 'Detail',
  },
};

export default Common;
