import { Flex, Segmented, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxStore';
import i18n, { LANGUAGE_TEXT } from 'i18n';
import { selectCurrentLng, setLng } from 'stores/themeSlice';

const { Text } = Typography;

const SwitchLang = () => {
  const dispatch = useAppDispatch();
  const lng = useAppSelector(selectCurrentLng);

  return (
    <Segmented
      size="small"
      options={[
        {
          label: (
            <Flex align="center" justify="center" gap={4}>
              <img height={18} src="/icons/us-flag.svg" alt="vn-flag" />
              <Text style={{ color: 'inherit' }}>en</Text>
            </Flex>
          ),
          value: LANGUAGE_TEXT.en.value,
        },
        {
          label: (
            <Flex align="center" justify="center" gap={4}>
              <img height={18} src="/icons/vn-flag.svg" alt="vn-flag" />
              <Text style={{ color: 'inherit' }}>vi</Text>
            </Flex>
          ),
          value: LANGUAGE_TEXT.vi.value,
        },
      ]}
      onChange={() => {
        const switchedLanguage = lng === 'en' ? 'vi' : 'en';
        dispatch(setLng(switchedLanguage));
        i18n.changeLanguage(switchedLanguage);
      }}
      value={lng}
      className="!rounded-3xl border border-solid p-1 !ml-5"
      style={{ display: 'inline-flex', width: 'fit-content' }}
    />
  );
};

export default SwitchLang;
