import type { FormItemProps } from 'antd';
import { Form, Input } from 'antd';
import type { InputProps } from 'antd/lib/input';
import type { ReactNode } from 'react';
import { useId } from 'react';

import HelperText from '../HelperText';
import { useField } from 'formik';

export interface TextInputProps extends InputProps {
  name: string;
  label?: string | ReactNode;
  className?: string;
  formItemProps?: FormItemProps;
  required?: boolean;
  helperText?: string;
  password?: boolean;
}

const TextInput = ({
  label,
  className = '',
  name,
  formItemProps,
  required,
  maxLength,
  helperText,
  password = false,
  ...props
}: TextInputProps) => {
  const id = useId();
  const [field, meta] = useField({
    name,
  });
  const isErr = meta.error && meta.touched;
  const InputComponent = password ? Input.Password : Input;
  return (
    <Form.Item
      className={className}
      colon={false}
      help={
        <HelperText
          error={isErr ? meta.error : ''}
          helperText={helperText}
          maxLength={maxLength}
          value={field.value}
        />
      }
      htmlFor={id}
      label={label}
      required={required}
      validateStatus={isErr ? 'error' : 'success'}
      {...formItemProps}
    >
      <InputComponent id={id} maxLength={maxLength} {...field} {...props} />
    </Form.Item>
  );
};

export default TextInput;
