import { Divider, Flex, type ModalFuncProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { BellIcon } from 'assets/icons';

let MODAL_CONFIRM_DEFAULT_OPTIONS: ModalFuncProps = {
  centered: true,
  icon: null,
  width: 360,
  okButtonProps: {
    type: 'link',
    size: 'small',
    className: 'w-full !text-primary',
  },
  cancelButtonProps: {
    type: 'link',
    size: 'small',
    className: 'w-full !text-primary',
  },
  footer: (_, { OkBtn, CancelBtn }) => (
    <>
      <Divider className="!my-2 !border-[#EDEDED]" />
      <Flex className="h-5">
        <OkBtn />
        <Divider type="vertical" className="min-h-8 !border-[#EDEDED]" />
        <CancelBtn />
      </Flex>
    </>
  ),
  title: (
    <Flex className="mb-2">
      <div className="bg-orange-400 rounded-full py-3 px-3 center border-[3px] border-solid border-white absolute -top-6 left-1/2 -translate-x-1/2">
        <BellIcon color="#ffffff" />
      </div>
    </Flex>
  ),
};

let MODAL_CONFIRM_CLOSE_BTN_ONLY_OPTIONS: ModalFuncProps = {
  centered: true,
  icon: null,
  width: 360,
  okButtonProps: {
    type: 'link',
    size: 'small',
    className: 'w-full !text-primary',
  },
  cancelButtonProps: {
    type: 'link',
    size: 'small',
    className: 'w-full !text-primary',
  },
  footer: (_, { OkBtn, CancelBtn }) => (
    <>
      <Divider className="!my-2 !border-[#EDEDED]" />
      <Flex className="h-5">
        {/* <OkBtn />
        <Divider type="vertical" className="min-h-8 !border-[#EDEDED]" /> */}
        <OkBtn />
      </Flex>
    </>
  ),
  title: (
    <Flex className="mb-2">
      <div className="bg-orange-400 rounded-full py-3 px-3 center border-[3px] border-solid border-white absolute -top-6 left-1/2 -translate-x-1/2">
        <BellIcon color="#ffffff" />
      </div>
    </Flex>
  ),
};
// let confirmModal: (content: string) => void;

export default () => {
  const { t } = useTranslation();
  MODAL_CONFIRM_DEFAULT_OPTIONS = {
    ...MODAL_CONFIRM_DEFAULT_OPTIONS,
    okText: t('Common:yes'),
    cancelText: t('Common:no'),
  };
  MODAL_CONFIRM_CLOSE_BTN_ONLY_OPTIONS = {
    ...MODAL_CONFIRM_CLOSE_BTN_ONLY_OPTIONS,
    cancelText: t('Common:yes'),
  };
  // confirmModal = (content) =>
  //   modalHook.confirm({ ...MODAL_CONFIRM_DEFAULT_OPTIONS, content: content });
  return null;
};

export { MODAL_CONFIRM_DEFAULT_OPTIONS, MODAL_CONFIRM_CLOSE_BTN_ONLY_OPTIONS };
