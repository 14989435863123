const Sider = {
  dashboard: 'Dashboard',
  tripManagement: {
    index: 'Trip Management',
    list: 'Trip list',
    create: 'Create trip',
    reviews: 'Rating',
    update: 'Edit trip',
    qna: 'Questions & Answer',
  },
  order: {
    index: 'Orders management',
    tripsaleform: 'Sales order',
    dispute: 'Disputes',
  },
  finance: {
    index: 'Finance',
    turnover: 'Turnover',
    statement: 'Statement',
  },
  account: {
    index: 'Account',
    setting: 'Settings',
  },
  chat: {
    index: 'Chat',
  },
  support: {
    index: 'Support',
    PopularQuestion: 'Frequently Asked Questions',
    ComplaintTicket: 'Complaint Ticket',
  },
  products: 'Product',
  users: 'User',
  logs: 'Logs',
  settings: 'Settings',
  tools: {
    index: 'Tools',
    ShortenUrl: 'Shorten URL',
    Map: 'Tripical Map',
    TranslateAI: 'Translate AI',
    ImageAI: 'Image AI',
  },
};

export default Sider;
