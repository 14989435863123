import { SearchCountryItem } from 'models/meilisearch';

import { meilisearchClient } from '.';

const countryIndex = meilisearchClient.index('country');

export const searchCountry = async (text: string) => {
  try {
    const res = await countryIndex.search(text || '');
    return res.hits as SearchCountryItem[];
  } catch (error) {
    return [];
  }
};
