import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import { api } from 'libs/api';
import { ExtendOptions } from 'models/common';

interface Options<TVariables, TData, TError>
  extends Omit<
      UseMutationOptions<TData, TError, TVariables>,
      'mutationFn' | 'meta'
    >,
    ExtendOptions {
  apiUrl: string | ((params: TVariables) => string);
}

const useMutate = <TVariables = unknown, TData = unknown, TError = unknown>(
  options: Options<TVariables, TData, TError>,
) => {
  const {
    apiUrl,
    method = 'post',
    successMessage,
    axiosConfig,
    defaultToast,
    ...otherOptions
  } = options;

  return useMutation({
    mutationFn: async (params: TVariables) => {
      const url = typeof apiUrl === 'string' ? apiUrl : apiUrl(params);
      const config = {
        method,
        url,
        data: params,
        ...axiosConfig,
      };
      const data = await api<TData>(config);
      return data as unknown as TData;
    },
    onSuccess: () => {
      if (defaultToast || successMessage) {
        notification.success({
          message: successMessage || 'Thao tác thành công',
        });
      }
    },
    ...otherOptions,
  });
};

export default useMutate;
