import regexs from 'constants/regex';
import type { InferType } from 'yup';
import { boolean, object, string } from 'yup';

const schema = (t) =>
  object({
    phoneOrEmail: string()
      .trim()
      .test((value, ctx) => {
        if (!value) {
          return ctx.createError({ message: t('Validation:required') });
        }

        if (value && value.includes('@')) {
          const isValid = string().email(value).isValidSync(value);
          return !isValid
            ? ctx.createError({ message: t('Validation:invalidEmail') })
            : true;
        }

        const isValid = regexs.phoneValidation.test(value);
        return !isValid
          ? ctx.createError({ message: t('Validation:invalidPhoneNumber') })
          : true;
      }),
    password: string().required(t('Validation:required')).strict(true),
    country: object({
      name: string(),
      phone: string(),
      flag: string(),
    }).notRequired(),
    trust: boolean().notRequired(),
  });

export type LoginInputFields = InferType<ReturnType<typeof schema>>;
export default schema;
