import { App, Form, Input, Modal, Typography } from 'antd';
import classNames from 'classnames';
import { MODAL_CONFIRM_DEFAULT_OPTIONS } from 'components/CustomAntd/Configs/modal';
import { errors, messages } from 'constants/validation';
import { useFormik } from 'formik';
import { changeMyPassword } from 'libs/api/user';
import { useRef } from 'react';
import * as Yup from 'yup';

const { Text } = Typography;

interface ChangePasswordModalProps {
  open: boolean;
  onCancel: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  open,
  onCancel,
}) => {
  const { modal, notification } = App.useApp();
  const validationSchema = useRef(
    Yup.object().shape({
      oldPassword: Yup.string().required(errors.common.required),
      newPassword: Yup.string().required(errors.common.required),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref('newPassword'), null],
        errors.password.not_match,
      ),
    }),
  );

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema.current,
    onSubmit: async ({ oldPassword, newPassword }) => {
      try {
        await changeMyPassword(oldPassword, newPassword);
        notification.success({ message: messages.UPDATE_SUCCESS });
      } catch (error) {
        notification.success({ message: messages.UPDATE_SUCCESS });

        modal.confirm({
          ...MODAL_CONFIRM_DEFAULT_OPTIONS,
          content: messages.UPDATE_FAILED,
        });
      } finally {
        handleCancel();
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm();
    onCancel();
  };

  return (
    <Modal
      title="Thay đổi mật khẩu"
      open={open}
      onOk={formik.submitForm}
      onCancel={handleCancel}
      destroyOnClose={false}
    >
      <form className="w-full" onSubmit={formik.handleSubmit}>
        <Form.Item>
          <Input.Password
            className={classNames('w-full  text-center', {
              'mb-2': formik.touched.oldPassword && formik.errors.oldPassword,
            })}
            placeholder="Mật khẩu cũ"
            name="oldPassword"
            size="large"
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            autoFocus
          />
          {formik.touched.oldPassword && formik.errors.oldPassword && (
            <Text className="w-full flex justify-center" type="danger">
              {formik.errors.oldPassword}
            </Text>
          )}
        </Form.Item>

        <Form.Item>
          <Input.Password
            className={classNames('w-full  text-center', {
              'mb-2': formik.touched.newPassword && formik.errors.newPassword,
            })}
            size="large"
            placeholder="Mật khẩu mới"
            name="newPassword"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            visibilityToggle
          />
          {formik.touched.newPassword && formik.errors.newPassword && (
            <Text className="w-full flex justify-center" type="danger">
              {formik.errors.newPassword}
            </Text>
          )}
        </Form.Item>

        <Form.Item>
          <Input.Password
            className={classNames('w-full  text-center', {
              'mb-2':
                formik.touched.confirmPassword && formik.errors.confirmPassword,
            })}
            placeholder="Xác nhận mật khẩu mới"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            size="large"
            visibilityToggle
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <Text className="w-full flex justify-center" type="danger">
              {formik.errors.confirmPassword}
            </Text>
          )}
        </Form.Item>
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
