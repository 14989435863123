import { App } from 'antd';
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from 'antd/lib/upload/interface';
import { MAX_UPLOAD_SIZE, MEDIA_MIME_TYPES } from 'constants/common';
import { useEffect, useMemo, useRef, useState } from 'react';
import { fixImgPath } from 'utils/common/helpers';
import { getBase64 } from 'utils/image';
import { uploadFile } from 'utils/upload';
import { useAppSelector } from './useReduxStore';
import { selectProfile } from 'stores/selectors';
import { MODAL_CONFIRM_CLOSE_BTN_ONLY_OPTIONS } from 'components/CustomAntd/Configs/modal';

export interface CustomFileUpload {
  uid: string;
  name: string;
  status: string;
  url: string;
  thumbUrl: string;
  file?: File | RcFile;
}

interface ImageDimension {
  width: number;
  height: number;
}

interface ParamOption {
  prefix?: string;
  multiple?: boolean;
  defaultImage?: any[];
  onImageList: ((images: CustomFileUpload[]) => void) | null;
  dimension?: ImageDimension;
}

export enum UploadStatus {
  Uploading = 'uploading',
  Done = 'done',
  Failed = 'failed',
}

export const useUploadImage = ({
  prefix = 'trip',
  multiple = true,
  defaultImage,
  onImageList,
  dimension,
}: ParamOption) => {
  const { notification, modal } = App.useApp();
  const setDefaultRef = useRef(false);
  const uidIndex = useRef(0);
  const fileListTemp = useRef<CustomFileUpload[]>([]);
  const [fileList, setFileList] = useState<CustomFileUpload[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const profile = useAppSelector(selectProfile);

  const handleDelete = (id: string) => {
    fileListTemp.current = fileListTemp.current.filter((v) => v.uid !== id);
    setFileList((prev) => prev.filter((item) => item.uid !== id));
  };

  const onUpdateFileList = (fileList: CustomFileUpload[]) => {
    setFileList(fileList);
    fileListTemp.current = fileList;
  };

  const setUploadData = async (
    file: File,
    data: {
      main: string;
      thumb: string;
      uid: string;
    },
    status = '',
  ) => {
    if (status === UploadStatus.Uploading) {
      setFileList(() => {
        const old = [...fileListTemp.current].filter((v) => v.uid !== data.uid);
        const newFileList = [
          ...old,
          {
            uid: data.uid,
            name: file.name,
            status,
            url: fixImgPath(data.main),
            thumbUrl: fixImgPath(data.thumb, true),
            file,
          },
        ];
        fileListTemp.current = newFileList;
        return newFileList;
      });
    } else {
      const newFileList = [...fileListTemp.current];
      const index = newFileList.findIndex((v) => v.uid === data.uid);
      if (index >= 0) {
        newFileList[index] = {
          uid: data.uid,
          name: file.name,
          status,
          url: fixImgPath(data.main),
          thumbUrl: fixImgPath(data.thumb, true),
          file,
        };
        setFileList(newFileList);
        fileListTemp.current = newFileList;
      }
    }
  };

  const checkImageDimension = (
    file: File,
    dimensionCondition?: ImageDimension,
    showMessage = false,
  ) => {
    if (!dimensionCondition) return true;
    let isChecking = false;
    return new Promise<boolean>((resolve, reject) => {
      if (isChecking) return;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      const img = new Image();
      reader.onload = (e) => {
        isChecking = true;
        if (!e?.target?.result) reject(false);
        img.src = e?.target?.result as any;
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          const isValid =
            width >= dimensionCondition?.width &&
            height >= dimensionCondition?.height;
          if (!isValid && showMessage) {
            modal.confirm({
              ...MODAL_CONFIRM_CLOSE_BTN_ONLY_OPTIONS,
              content: `Hình ảnh phải có kích thước LỚN HƠN ${dimensionCondition.width}x${dimensionCondition.height}`,
            });
          }
          isChecking = false;
          resolve(isValid);
        };
      };
    });
  };

  const checkImageSize = (file: File, showMessage = false) => {
    const isLt2M = file.size < MAX_UPLOAD_SIZE;
    if (!isLt2M && showMessage) {
      modal.confirm({
        ...MODAL_CONFIRM_CLOSE_BTN_ONLY_OPTIONS,
        content: `Kích thước ảnh lớn hơn ${MAX_UPLOAD_SIZE / 1024 / 1024}MB`,
      });
    }
    return isLt2M;
  };

  const checkImageFormat = (file: File, showMessage = false) => {
    const isValidImage =
      !!file.type && Object.values(MEDIA_MIME_TYPES).includes(file.type);
    if (!isValidImage && showMessage) {
      modal.confirm({
        ...MODAL_CONFIRM_CLOSE_BTN_ONLY_OPTIONS,
        content: `Định dạng file phải là ${Object.keys(MEDIA_MIME_TYPES).join(
          '/',
        )}`,
      });
    }
    return isValidImage;
  };

  const beforeUpload = async (file: File, showMessage = false) => {
    let isValidDimension = true;
    // Check file format
    const isValidImage = checkImageFormat(file, showMessage);
    // Check file size case
    const isLt2M = checkImageSize(file, showMessage);
    // Check dimension case
    isValidDimension = await checkImageDimension(file, dimension, showMessage);

    return isValidImage && isLt2M && isValidDimension;
  };

  const handleUploadFile = async (file: RcFile) => {
    setIsLoading(true);
    const previewImg = await getBase64(file);

    const uid = uidIndex.current.toString();
    uidIndex.current++;
    setUploadData(
      file,
      {
        main: previewImg,
        thumb: previewImg,
        uid,
      },
      UploadStatus.Uploading,
    );
    try {
      const data = await uploadFile(file, profile?.uid, prefix);

      setUploadData(
        file,
        {
          main: data.main,
          thumb: data.thumb,
          uid,
        },
        UploadStatus.Done,
      );
    } catch (error) {
      setUploadData(
        file,
        {
          main: previewImg,
          thumb: previewImg,
          uid,
        },
        UploadStatus.Failed,
      );

      modal.confirm({
        ...MODAL_CONFIRM_CLOSE_BTN_ONLY_OPTIONS,
        content: 'Có lỗi xảy ra trong lúc upload, hãy xóa và thử lại!',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (onImageList) {
      onImageList(fileList);
    }
  }, [fileList]);

  useEffect(() => {
    if (!defaultImage && fileList.length > 0) {
      setFileList([]);
      fileListTemp.current = [];
      return;
    }

    if (
      defaultImage &&
      defaultImage?.length > 0 &&
      !fileList.length &&
      !setDefaultRef.current
    ) {
      setFileList(defaultImage);
      fileListTemp.current = defaultImage;
      setDefaultRef.current = true;
      return;
    }
  }, [defaultImage]);

  const draggerDefaultProps: any = useMemo(
    () => ({
      name: 'file',
      action: null,
      multiple,
      showUploadList: false,
      fileList: fileList,
      accept: 'image/*',
      beforeUpload,
      onChange: async (info: UploadChangeParam<UploadFile<any>>) => {
        for (const fileItem of info.fileList) {
          const file = fileItem.originFileObj;
          if (!file) continue;
          await beforeUpload(file);
        }
      },
      customRequest: (options: any) => {
        handleUploadFile(options.file);
      },
    }),
    [fileList],
  );
  return {
    beforeUpload,
    handleDelete,
    setFileList,
    onUpdateFileList,
    draggerDefaultProps,
    fileList,
    isLoading,
  };
};
