import { Card } from 'antd';
import NewDetailModal from 'components/Modals/NewDetailModal';
import Pagination from 'components/Pagination';
import { NewsTypes } from 'constants/common';
import { getArticlesByType } from 'libs/api/article';
import { Article } from 'models/article';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NewsItem from 'sections/News/NewsItem';

function News() {
  const { t } = useTranslation();
  const [articles, setArticles] = useState<Article[]>([]);
  const [detailData, setDetailData] = useState<Article>();
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const DEFAULT_TAKE = 8;
  const handleGetArticle = async (pageIndex = 0) => {
    try {
      const res = await getArticlesByType(NewsTypes.PRIVATE, 8, pageIndex);
      setArticles(res.data);
      setCount(res.count);
    } catch (error) {}
  };
  const handleChangePage = (pageIndex = 0) => {
    setPageNumber(pageIndex + 1);
    handleGetArticle(pageIndex);
  };
  useEffect(() => {
    handleGetArticle(pageNumber);
  }, []);

  return (
    <Card
      className="hidden md:block w-68 mx-6"
      classNames={{
        body: 'p-0',
      }}
    >
      <h3 className="text-xl p-4">{t('Common:news.title')}</h3>
      <div className="max-h-[calc(100vh-220px)] h-full overflow-y-auto">
        {articles.map((v) => (
          <NewsItem key={v._id} data={v} onClick={() => setDetailData(v)} />
        ))}
      </div>
      <NewDetailModal
        open={!!detailData}
        data={detailData}
        onCancel={() => setDetailData(undefined)}
      />
      <Pagination
        className="mt-5 text-center"
        pageIndex={pageNumber - 1}
        pageSize={DEFAULT_TAKE}
        total={count}
        gotoPage={handleChangePage}
      />
    </Card>
  );
}

export default News;
