import { Flex } from 'antd';

export interface HelperTextProps {
  maxLength?: number;
  error?: string;
  helperText?: string;
  name?: string;
  value?: string;
}
const HelperText = ({
  maxLength,
  error,
  helperText,
  name,
  value,
}: HelperTextProps) => {
  if (maxLength || error || helperText) {
    return (
      <Flex wrap="nowrap">
        <div
          className="ant-form-item-explain ant-form-item-explain-connected"
          id={`${name}_help`}
          role="alert"
        >
          <div
            className={
              error
                ? 'ant-form-item-explain-error'
                : 'ant-form-item-explain-success'
            }
          >
            {error || helperText}
          </div>
        </div>
        <div style={{ height: 32 }}>
          {maxLength && `${(value || '').length}/${maxLength}`}
        </div>
      </Flex>
    );
  }
  return null;
};

export default HelperText;
