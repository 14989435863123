import { GetLatestArticleResponse } from 'models/article';
import { api } from '.';

export const getArticlesByType = (
  type: string,
  limit: number,
  page: number,
) => {
  return api.get(`/article/search-by-type/${type}`, {
    params: {
      limit,
      page,
    },
  }) as Promise<GetLatestArticleResponse>;
};
