import {
  noRoleRoutes,
  tripperRoutes,
  builderRoutes,
} from './../constants/permission';
import { UserRole } from 'constants/common';
import { selectProfile } from 'stores/selectors';
import { useAppSelector } from './useReduxStore';
import { getPageByPath } from 'utils/common/helpers';
import { useMemo } from 'react';

const useCheckRoleAccessPage = (
  accessRoles: UserRole[],
  pagePathname: string,
) => {
  const myProfile = useAppSelector(selectProfile);
  const myRoles = myProfile?.roles || [];
  const page = useMemo(() => getPageByPath(pagePathname), [pagePathname]);

  const accessResult = useMemo(() => {
    // If user has no roles and the page requires roles, deny access.
    if (!myRoles.length && !noRoleRoutes.includes(pagePathname)) {
      return { validAccess: false, page };
    }

    // Check access for tripper and builder specific routes.
    let validAccess = true;
    if (
      tripperRoutes.includes(pagePathname) ||
      builderRoutes.includes(pagePathname)
    ) {
      validAccess = myRoles.some((role) => accessRoles.includes(role));
    }

    return { validAccess, page };
  }, [myRoles, pagePathname, accessRoles, page]);

  return accessResult;
};

export default useCheckRoleAccessPage;
