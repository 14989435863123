import env from 'constants/env';

interface PageProtectionProps {
  title?: string;
  subtitle?: string;
  desc?: string;
}

function PageProtection(props: PageProtectionProps) {
  const { title, subtitle, desc } = props;
  const defaultSubtile = `Nâng cấp tài khoản để truy cập vào trang này`;
  const defaultDesc = `Bạn cần được cấp quyền để có thể xem, tạo hoặc chỉnh sửa nội dung của các trang này`;
  return (
    <div className="my-16 mx-4 md:text-center pb-[200px]">
      <h1 className="text-3xl mb-5 font-semibold">{title}</h1>
      <p className="md:text-xl mt-2 font-semibold">
        {subtitle || defaultSubtile}
      </p>
      <p className="text-sm md:text-base text-grey_note font-normal mb-2 py-[5px]">
        {desc || defaultDesc}
      </p>
      <a
        className="link"
        href={`${env.portalAppHost}/account/upgrade`}
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <button className="mt-2 text-white font-medium bg-primary cursor-pointer border-none rounded-2xl px-20 py-2">
          Trang cập nhật thông tin người bán
        </button>
      </a>
    </div>
  );
}

export default PageProtection;
