import { message } from 'antd';
import axios, { AxiosResponse } from 'axios';
import env from 'constants/env';
import store from 'libs/redux/store';
import { LngLat } from 'maplibre-gl';
import { actions } from 'sections/TripManagement/checkpointForm/slices';
import { updateSessionStatus } from 'stores/authSlice';
import { authErrorMessages } from 'utils/error';
import { clearToken, refreshToken } from './auth';

// for multiple requests
let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.defaults.withCredentials = true;

const TIMEOUT_REQUEST_DEFAULT = 5 * 60 * 1000; //300s
export const api = axios.create({
  baseURL: env.apiUrl,
  timeout: TIMEOUT_REQUEST_DEFAULT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  paramsSerializer: {
    // ko gửi brackets cho array params
    indexes: null,
  },
});

api.interceptors.request.use(async function (config) {
  return config;
});

api.interceptors.response.use(
  (response: AxiosResponse) => {
    // expose ilocale client-side for default map view
    if (response?.config?.url?.includes('profile')) {
      const ilocaleHeader = response.headers['ilocale'];
      if (ilocaleHeader) {
        const [_, city, lat, lng] = ilocaleHeader.split('_');
        const latitude = parseFloat(lat);
        const longitude = parseFloat(lng);

        if (!isNaN(latitude) && !isNaN(longitude)) {
          const center = new LngLat(longitude, latitude);
          store.dispatch(actions.setViewMap({ center }));
        }
      }
    }

    return response.data;
  },
  async (error) => {
    const originalRequest = error.config;
    const errMessage = error?.response?.data?.message as string;

    if (
      errMessage?.includes(authErrorMessages.TOKEN_EXPIRED) &&
      !originalRequest?._retry
    ) {
      originalRequest._retry = true;
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(() => {
            return api(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      isRefreshing = true;
      return new Promise(function (resolve, reject) {
        refreshToken()
          .then((data: any) => {
            processQueue(null, data.accessToken);
            resolve(api(originalRequest));
          })
          .catch(async (error: AxiosResponse) => {
            //. AxiosResponse, not AxiosError, bsaed on return Promise.reject
            if (error?.status === 401) {
              clearToken();
              processQueue(error, null);

              store.dispatch(
                updateSessionStatus({
                  request: originalRequest,
                  promisePosition: resolve,
                }),
              );
            } else {
              // For all other errors, just log them and show toast "Đường truyền không ổn định hoặc lỗi hệ thống"
              // message.warning(
              //   errMessage ||
              //     'Đường truyền trục trặc, vui lòng thử lại sau ít phút',
              // );
            }
            // reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    if (error?.code === 'ECONNABORTED') {
      message.warning('Đường truyền trục trặc, vui lòng thử lại sau ít phút');
    }

    return Promise.reject(error.response);
    // giữ nguyên form của AxiosResponse
  },
);
