import AppRoutes from 'constants/routes';
import { useAppDispatch } from 'hooks/useReduxStore';
import { runSpecificRequest } from 'libs/api/auth';
import { getMyProfile } from 'libs/api/user';
import { resetAllSlice } from 'libs/redux/global.action';
import { useNavigate } from 'react-router-dom';
import Login from 'sections/Login';
import { updateProfile, updateSessionStatus } from 'stores/authSlice';

import { Modal } from 'antd';

interface LoginPopupProps {
  open: boolean;
  failedRequest?: {
    request: any;
    promisePosition: any;
  };
  onClose: () => void;
}

const LoginPopup: React.FC<LoginPopupProps> = ({
  open,
  failedRequest,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCloseLoginPopup = async () => {
    try {
      const data = await getMyProfile();
      dispatch(updateProfile(data));
      if (failedRequest)
        runSpecificRequest(
          failedRequest.promisePosition,
          failedRequest.request,
        );
      dispatch(updateSessionStatus(undefined));
    } catch (error) {
      handleClose();
    }
  };

  const handleClose = () => {
    dispatch(resetAllSlice());
    navigate(AppRoutes.SIGN_IN);
    onClose && onClose();
  };

  const onCloseLoginPopup = async (refreshProfile?: boolean) => {
    if (refreshProfile) {
      try {
        const data = await getMyProfile();
        dispatch(updateProfile(data));
      } catch (error) {
        handleClose();
      }
    }
    onClose && onClose();
  };
  return (
    <Modal open={open} onCancel={handleClose} footer={() => null}>
      <Login
        hideRoleAccount
        onSuccessOnPopup={handleCloseLoginPopup}
        onCloseLoginPopup={onCloseLoginPopup}
      />
    </Modal>
  );
};

export default LoginPopup;
