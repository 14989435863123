import {
  DisputeDetailResponse,
  DisputeItemResponse,
  FinishDisputePayload,
  PatchDisputeReplyPayload,
} from 'models/dispute';
import { api } from '.';
import { DISPUTE_STATUS_PAYLOAD } from 'constants/common';

export const getListDispute = (
  page = 0,
  limit = 10,
  status?: DISPUTE_STATUS_PAYLOAD,
  lt48h?: boolean,
) => {
  const apiURL = 'dispute/get-by-seller-id';

  return api.get(apiURL, {
    params: {
      limit,
      page,
      lt48h: lt48h,
      status: status || undefined,
    },
  }) as Promise<DisputeItemResponse>;
};

export const getListDisputeByStatus = (
  status: DISPUTE_STATUS_PAYLOAD,
  page = 0,
  limit = 10,
) => {
  return api.get(`/dispute/get-by-status`, {
    params: {
      status,
      limit,
      page,
    },
  }) as Promise<DisputeItemResponse>;
};

export const getDisputeDetail = (id: string) => {
  return api.get(`dispute/${id}`) as Promise<DisputeDetailResponse>;
};

export const patchDisputeReply = (payload: PatchDisputeReplyPayload) => {
  return api.patch('dispute/reply', payload) as Promise<any>;
};

export const finishDispute = (payload: FinishDisputePayload) => {
  return api.patch('dispute/finished-dispute', payload) as Promise<any>;
};
