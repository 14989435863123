export type IGetSalesByTimeRangeParams = {
  from: string;
  to: string;
  timeZone: string;
};

export type IGetSalesByTimeRangeResponse = {
  date: {
    value: string;
  };
  amount: number;
  count?: number;
};

export type ISumSalesByTimeRangeResponse = {
  netPay: number;
  grossPay: number; // gross pay, including payment fee by customer
  netSales: number;
  grossSales: number; // based on selling price
  promote: number;
  otherFee: number;
  coms: number;
  count: number;
  refundAmount: number; // sum up refunded amount
};

export type IGetSalesByTripIdResponse = {
  tripId: string;
  amount: number;
  count: number;
  trip: {
    name: string;
  };
};

// gửi lên BE bằng múi giờ UTC, dựa trên local time
export const sendingTimeFormat = 'YYYY-MM-DD HH:mm:ss';
