import enUS from 'antd/lib/locale/en_US';
import viVN from 'antd/lib/locale/vi_VN';
import 'moment/locale/vi';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import * as locales from './resources';

export const LANGUAGE_TEXT = {
  vi: {
    value: 'vi',
    antd: viVN,
  },
  en: {
    value: 'en',
    antd: enUS,
  },
};

const DETECTION_OPTIONS = {
  order: ['localStorage'],
  caches: ['localStorage'],
};

i18n
  // pass the i18n instance to react-i18next.
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    fallbackLng: 'vi',
    resources: {
      ...Object.entries(locales).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value,
        }),
        {},
      ),
    },
    detection: DETECTION_OPTIONS,
    interpolation: {
      escapeValue: false,
    },
  });

export const { t } = i18n;

export default i18n;
