import { Flex, Image, Typography } from 'antd';
import HelperText from 'components/Form/HelperText';
import env from 'constants/env';
import AppRoutes from 'constants/routes';
import useMutate from 'hooks/useMutate';
import { LoginRequest, LoginResponse } from 'models/auth';
import { AuthQuery } from 'query/auth';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatUsername } from 'utils/format';
import LoginForm from './Form';
import { LoginInputFields } from './Form/schema';

const { Title } = Typography;

interface LoginProps {
  hideRoleAccount?: boolean;
  onSuccessOnPopup?: () => void;
  onCloseLoginPopup?: (refreshProfile?: boolean) => void;
}

const Login: React.FC<LoginProps> = ({
  hideRoleAccount,
  onSuccessOnPopup,
  onCloseLoginPopup,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { mutate: requestLogin, isLoading } = useMutate<
    LoginRequest,
    LoginResponse
  >(AuthQuery.login);
  const handleLogin = async (values: LoginInputFields) => {
    requestLogin(
      {
        username: formatUsername(
          values?.phoneOrEmail || '',
          values?.country?.phone,
        ),
        password: values?.password,
        trust: values?.trust,
      },
      {
        onSuccess: () => {
          if (onSuccessOnPopup) {
            // Case login in popup
            onSuccessOnPopup();
          } else {
            // Case normal login site
            navigate(AppRoutes.ROOT);
          }
        },
      },
    );
  };

  const handleSocialLogin = (provider: 'google' | 'facebook') => {
    // Define desired width and height
    const width = 600;
    const height = 600;

    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
    width=${width},height=${height},left=${left},top=${top}`;
    const popup = window.open(
      `${env.apiUrl}/${provider}/seller`,
      'Tripical social login',
      params,
    );

    if (popup) {
      // Create a timer that checks the popup's closed status every 500 milliseconds
      const checkPopupClosed = setInterval(function () {
        if (popup.closed) {
          // Stop the timer when the popup is closed
          clearInterval(checkPopupClosed);

          // nếu là từ page login, thì đá về root
          if (location.pathname === AppRoutes.SIGN_IN) {
            navigate(AppRoutes.ROOT);
          }
          // To-do
          // đóng modal login
          if (onCloseLoginPopup) {
            onCloseLoginPopup(true);
          }
        }
      }, 500);
    }
  };

  return (
    <Flex justify="space-between" className="w-full max-w-7xl ">
      {!hideRoleAccount && (
        <Flex className="hidden lg:flex center">
          <Image
            src={`${env.imageDomain}/static/sellercenter/seller_login.webp`}
            alt=""
            width={559}
            height={465}
            preview={false}
          />
        </Flex>
      )}
      <Flex
        vertical
        align="center"
        style={{ width: '100%', height: '100%' }}
        className="px-11 py-6 max-w-md mx-auto shadow-card rounded-2xl"
        gap={32}
      >
        <Title level={2} className="mt-4">
          {t('Common:signIn')}
        </Title>
        <LoginForm
          handleSocialLogin={handleSocialLogin}
          onSubmit={handleLogin}
          loading={isLoading}
        />
      </Flex>
    </Flex>
  );
};

export default Login;
