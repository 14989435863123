import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxStore';
import { getMyProfile } from 'libs/api/user';
import { updateProfile } from 'stores/authSlice';
import AppRoutes from 'constants/routes';
import { selectProfile, selectSessionExpired } from 'stores/selectors';
import { resetAllSlice } from 'libs/redux/global.action';
import LoginPopup from 'components/LoginPopup';
import { checkIsAu, getUserAgent } from 'libs/api/auth';
import { App } from 'antd';
import { MODAL_CONFIRM_CLOSE_BTN_ONLY_OPTIONS } from 'components/CustomAntd/Configs/modal';

const CHECK_DEVICE_KEY = 'tripical_check_mobile_device';

export default function PrivateRoute({ children }: { children: JSX.Element }) {
  const { modal } = App.useApp();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const myProfile = useAppSelector(selectProfile);
  const sessionExpired = useAppSelector(selectSessionExpired);
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  useEffect(() => {
    if (sessionExpired) {
      modal.confirm({
        ...MODAL_CONFIRM_CLOSE_BTN_ONLY_OPTIONS,
        content: 'Phiên hết hạn. Vui lòng đăng nhập lại để tiếp tục.',
        onCancel: () => setShowLoginPopup(true),
      });
    } else {
      setShowLoginPopup(false);
    }
  }, [sessionExpired]);

  const isLoggedIn = Boolean(myProfile.uid);
  const getMyProfileData = async () => {
    try {
      if (!checkIsAu()) {
        throw new Error();
      }
      const data = await getMyProfile();
      dispatch(updateProfile(data));
    } catch (error) {
      dispatch(resetAllSlice());
      navigate(AppRoutes.SIGN_IN);
    }
  };

  const checkDeviceOpening = () => {
    const userAgent = getUserAgent();
    const isMobile = /Mobi|Android|iPhone|BlackBerry|iPod/i.test(userAgent);
    const checkDevice = sessionStorage.getItem(CHECK_DEVICE_KEY);
    if (!isMobile || checkDevice) return;
    modal.confirm({
      ...MODAL_CONFIRM_CLOSE_BTN_ONLY_OPTIONS,
      content: 'Trang tạo nội dung chỉ phù hợp với giao diện máy tính',
      onCancel: () => sessionStorage.setItem(CHECK_DEVICE_KEY, 'true'),
    });
  };

  useEffect(() => {
    if (!isLoggedIn) getMyProfileData();
    else navigate(AppRoutes.DASHBOARD.index);
    checkDeviceOpening();
  }, []);

  return (
    <>
      <LoginPopup
        failedRequest={sessionExpired}
        open={showLoginPopup}
        onClose={() => setShowLoginPopup(false)}
      />
      {children}
    </>
  );
}
