const Common = {
  //Global
  yes: 'Đồng ý',
  no: 'Bỏ qua',
  cancel: 'Huỷ',
  ok: 'Đồng ý',
  apply: 'Áp dụng',
  send: 'Gửi',
  empty: 'Trống',
  search: 'Tìm kiếm',
  error: 'Lỗi',
  done: 'Xong',
  sortTrips: 'Sắp xếp trạm',
  new: 'Mới',
  check: 'Kiểm tra',
  //Auth
  signIn: 'Đăng nhập',
  signUp: 'Đăng ký',
  notHaveAccount: 'Chưa có tài khoản?',
  haveAccount: 'Đã có tài khoản?',
  //Authorization
  accessDeniedCode: '403',
  accessDeniedDesc: 'Xin lỗi, bạn không được phép truy cập trang này.',
  notFoundCode: '404',
  notFoundCodeDesc: 'Xin lỗi, trang bạn đã truy cập không tồn tại.',
  //Button
  submit: 'Submit',
  backHome: 'Trở về Trang chủ',
  //Page Titles
  dashboard: {
    welcomeMessage:
      'Chào mừng đến với <strong>Tripical - Trip maker Center</strong>! Nơi xây dựng những lịch trình du lịch hay.',
    statistics: 'Thống kê',
    last7Days: '7 ngày qua',
    lastMonth: 'Tháng qua',
  },
  news: {
    title: 'Bản tin',
  },
  account: {
    title: 'Tài khoản',
    setting: 'Cài đặt',
  },
  finance: {
    title: 'Tài chính',
    turnover: 'Doanh thu',
    statement: 'Sao kê',
  },
  orders: {
    title: 'Đơn hàng',
    tripsaleform: 'Đơn bán trip',
    dispute: 'Khiếu nại',
  },
  tripManagement: {
    title: 'Quản lý trip',
    list: 'Danh sách trip',
    create: 'Tạo Trip',
    update: 'Sửa trip',
    reviews: 'Danh sách đánh giá',
    qnaTitle: 'Quản lý hỏi đáp',
    qnaColumn: 'Quản lý trip',
    qnaTab: 'Hỏi và đáp',
  },
  tripList: {
    trip: 'Lịch trình',
    price: 'Giá tiền',
    status: 'Trạng thái',
    display: 'Hiển thị',
    action: 'Thao tác',
    skuName: 'Tên SKU',
    tripName: 'Tên Trip',
    enterSku: 'Nhập SKU',
    enterTripName: 'Nhập tên trip',
    selectCategory: 'Lựa chọn danh mục',
    myTrips: 'Trips của tôi',
    pendingApproval: 'Chờ Duyệt',
    published: 'Đang bán',
    tripLimit: 'Giới hạn đăng tải trip (Chờ duyệt + Đang bán)',
    remainingListings: 'Lượt niêm yết còn lại',
  },
  createTrip: {
    marqueeIntro:
      'Lịch trình du lịch tự túc không chỉ đơn giản là tường thuật hay review! Bạn {{name}} hãy cùng Tripical tạo ra sự khác biệt! Xem thêm',
    instructText: 'Outline hướng dẫn viết trip.',
    categoryList: 'Danh sách danh mục',
    destination: 'Điểm đến',
    districtTownLevel: 'Cấp huyện trấn, thành thị',
    shortIntroduction:
      'Giới thiệu ngắn về lịch trình này. Gạch đầu dòng mỗi hàng',
    tripIntroductionTitle: 'Giới thiệu trip',
    includesAllDesignCosts:
      'Bao gồm tất cả những chi phí thiết kế trong lịch trình.',
    upload: 'đăng tải',
    prominentImages: 'Hình ảnh nổi bật',
    dragDropImageHere: 'Kéo thả hình vào đây hoặc',
    uploadFromYourDevice: ' từ máy của bạn',
    atLeastFourImages: 'Ít nhất 4 hình độ phân giải tối thiểu 800x800 pixel.',
    egTickets:
      'Ví dụ: Vé, tàu, xe, thuyền... cần đặt trước. Sđt đặt trước / bảng giá. Tối đa 400 ký tự.',
    egSightseeingTickets:
      'Ví dụ: Vé tham quan, vào cổng cần đặt trước. Hoặc bảng giá. Tối đa 400 ký tự.',
    accommodationDiningNotes:
      'Địa điểm lưu trú/ăn uống cần lưu ý và đặt trước nếu cần. Tối đa 600 ký tự.',
    tripName: 'Tên trip',
    shortDescription: 'Mô tả ngắn',
    tripIntroduction:
      'Giới thiệu trip (Hiển thị cho người dùng trước khi mua & SEO)',
    modeOfTransportation: 'Loại hình di chuyển',
    estimatedBudget: 'Ngân sách dự kiến',

    selectTool: 'Chọn dụng cụ',
    estimatedPerson: 'Dự kiến/người.',
    prepareContentCheck:
      'Kiểm tra nội dung cần chuẩn bị trước chuyến đi bằng trợ lý Mon AI, kết quả chỉ mang tính tham khảo',
    prepareBeforeTrip: 'Cần chuẩn bị trước chuyến đi',
    transportation: 'Di chuyển',
    sightseeing: 'Tham quan',
    accommodationDining: 'Lưu trú/ăn uống',
    essentialsToBring: 'Vật dụng mang theo',
    stationDetails: 'Chi tiết các trạm',
    preview: 'Xem trước',
    saveTrip: 'Lưu trip',
    unsavedChanges:
      'Thay đổi của bạn chưa được lưu. Vui lòng nhấn Lưu trước để tiếp tục.',
    enterContent: 'Nhập nội dung',
    visitErr1: 'Vui lòng nhập tên địa điểm',
    visitErr2: 'Vui lòng chọn vị trí trên bản đồ',
  },
  checkpoint: {
    stationCount: 'Lượt trạm',
    stationType: 'Loại trạm',
    date: 'Ngày',
    time: 'Thời gian',
    stationName: 'Tên địa điểm trạm',
    mapLocation: 'Vị trí bản đồ',
    maxCharacters: 'Ký tự tối đa:',
    recommendedWords: 'Số từ khuyến nghị:',
    cancelChanges: 'Hủy thay đổi',
    saveTemporary: 'Lưu tạm',
    saveTemporaryOnly: 'Chỉ lưu tạm (không cập nhật lên hệ thống)',
    addCheckpoint: 'Thêm trạm',
    startLocation: 'Xuất phát',
    endLocation: 'Kết thúc',
    location: 'Địa điểm',
    uploadImage: 'Tải ảnh lên',
    maxCharacters40: 'Tối đa 40 ký tự',
    createRoute: 'Tạo dẫn đường',
  },
  support: {
    title: 'Hỗ trợ',
    popularquestion: 'Câu hỏi phổ biến',
    disputeticket: 'Ticket khiếu nại',
  },
  chat: {
    title: 'Chat',
    detail: 'Chi tiết',
  },
};

export default Common;
