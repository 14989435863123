import { useQuery } from '@tanstack/react-query';
import useDebounce from 'hooks/useDebounce';
import { searchCity } from 'libs/meilisearch/cities';

import { searchCountry } from 'libs/meilisearch/country';

export const MeilisearchQueryKey = {
  SEARCH_COUNTRY: '@meilisearch/SEARCH_COUNTRY',
  SEARCH_CITIES: '@meilisearch/SEARCH_CITIES',
};

export const useSearchCountry = (queryString: string) => {
  const debounceValue = useDebounce(queryString, 300);
  return useQuery({
    queryKey: [MeilisearchQueryKey.SEARCH_COUNTRY, debounceValue],
    queryFn: () => searchCountry(debounceValue),
    keepPreviousData: true,
  });
};

export const useSearchCitites = (queryString: string) => {
  const debounceValue = useDebounce(queryString, 300);
  return useQuery({
    queryKey: [MeilisearchQueryKey.SEARCH_CITIES, debounceValue],
    queryFn: () => searchCity(debounceValue),
    keepPreviousData: true,
  });
};
