import { useEffect, useMemo, useState } from 'react';
import { message as antdMessage } from 'antd';
import { getDisputeDetail } from 'libs/api/dispute';
import { DisputeDetailResponse, DisputeItem } from 'models/dispute';

interface ParamOptions {
  id: string;
}

const useDisputeDetail = (params: ParamOptions) => {
  const { id } = params;

  const [disputeDetailData, setDisputeDetailData] =
    useState<DisputeDetailResponse>();
  const [loading, setLoading] = useState<boolean>(true);

  const fetchDisputeDetail = async () => {
    try {
      const response = await getDisputeDetail(id);
      if (response) {
        setDisputeDetailData(response);
      }
    } catch (error: any) {
      antdMessage.error(error.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDisputeDetail();
  }, []);

  const refreshDispute = () => fetchDisputeDetail();

  return useMemo(
    () => ({
      disputeDetailData,
      loading,
      refreshDispute,
    }),
    [disputeDetailData, loading],
  );
};

export default useDisputeDetail;
