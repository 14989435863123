import { Button, ButtonProps, ConfigProvider } from 'antd';
import { GLOBAL_COLOR } from 'styles/color';

const BlackButton = (props: ButtonProps) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: GLOBAL_COLOR.BLACK_BUTTON,
            colorText: GLOBAL_COLOR.ORANGE_400,
            colorPrimaryHover: GLOBAL_COLOR.GREY_700,
            defaultBorderColor: GLOBAL_COLOR.GREY_200,
            defaultColor: GLOBAL_COLOR.GREY_700,
            defaultHoverColor: GLOBAL_COLOR.GREY_700,
            defaultHoverBorderColor: GLOBAL_COLOR.GREY_200,
            lineHeight: 1,
            lineHeightLG: 1,
          },
        },
      }}
    >
      <Button {...props}>{props.children}</Button>
    </ConfigProvider>
  );
};

export default BlackButton;
