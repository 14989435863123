import { Card, CardProps, ConfigProvider } from 'antd';
import useSwitchThemeMode from 'hooks/useSwitchThemeMode';
import { GLOBAL_COLOR } from 'styles/color';

const CustomCard = (
  props: CardProps & {
    bg?: {
      blackMode?: string;
      lightMode?: string;
    };
    border?: {
      blackMode?: string;
      lightMode?: string;
    };
  },
) => {
  const { bg, border } = props;
  const { themeMode, ThemeModeColor } = useSwitchThemeMode();
  const lightMode = themeMode === ThemeModeColor.LIGHT;
  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextBase: lightMode ? GLOBAL_COLOR.BLACK : GLOBAL_COLOR.WHITE,
        },
        components: {
          Card: {
            colorBgContainer: lightMode
              ? bg?.lightMode || GLOBAL_COLOR.WHITE
              : bg?.blackMode || GLOBAL_COLOR.DARK_200,
            colorBorderSecondary: lightMode
              ? border?.lightMode || GLOBAL_COLOR.DIVIDER_WHITE
              : border?.blackMode || GLOBAL_COLOR.DIVIDER_WHITE,
            colorText: lightMode ? GLOBAL_COLOR.BLACK : GLOBAL_COLOR.WHITE,
          },
        },
      }}
    >
      <Card {...props}>{props.children}</Card>
    </ConfigProvider>
  );
};

export default CustomCard;
