const Sider = {
  dashboard: 'Dashboard',
  tripManagement: {
    index: 'Quản lý trip',
    list: 'Danh sách trip',
    create: 'Tạo trip',
    update: 'Sửa trip',
    reviews: 'Danh sách đánh giá',
    qna: 'Quản lý hỏi đáp',
  },
  order: {
    index: 'Đơn hàng',
    tripsaleform: 'Đơn bán trip',
    dispute: 'Khiếu nại',
  },
  finance: {
    index: 'Tài chính',
    turnover: 'Doanh thu',
    statement: 'Sao kê',
  },
  account: {
    index: 'Tài khoản',
    setting: 'Cài đặt',
  },
  chat: {
    index: 'Chat',
  },
  support: {
    index: 'Hỗ trợ',
    PopularQuestion: 'Câu hỏi phổ biến',
    ComplaintTicket: 'Ticket khiếu nại',
  },
  products: 'Product',
  users: 'User',
  logs: 'Logs',
  settings: 'Setting',
  tools: {
    index: 'Công cụ',
    ShortenUrl: 'Rút gọn link',
    Map: 'Bản đồ',
    TranslateAI: 'AI Dịch thuật',
    ImageAI: 'Vẽ bằng AI',
  },
};

export default Sider;
