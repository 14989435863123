import { SimpleUser } from 'models/user';
import { createSlice } from '@reduxjs/toolkit';

// Define a type for the slice state
export interface AuthState {
  profile: SimpleUser;
  sessionExpired?: {
    request: any;
    promisePosition: any;
  };
}

// Define the initial state using that type
export const initialState: AuthState = {
  profile: {} as SimpleUser,
  sessionExpired: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateProfile(state, action) {
      state.profile = action.payload;
    },
    updateSessionStatus(state, action) {
      state.sessionExpired = action.payload;
    },
  },
});

export const { updateProfile, updateSessionStatus } = authSlice.actions;

export default authSlice;
