import { LoginResponse, RegisterResponse } from 'models/auth';
import { LocalStorage } from 'utils/common/auth';
import { api } from '.';

export const login = (username: string, password: string, trust?: boolean) => {
  return api.post('/auth/login', {
    username,
    password,
    // did: userAgent,
    trust,
  }) as Promise<LoginResponse>;
};

export const sendOtp = (phoneNumber: string, recaptchaToken: string) => {
  return api.post('/phone/send-otp', {
    phoneNumber,
    recaptchaToken,
  });
};

export const verifyOtp = (code: string, phone: string, session: string) => {
  return api.post('/phone/verify-otp', {
    verificationCode: code,
    phoneNumber: phone,
    sessionInfo: session,
  });
};

export const register = (username: string, password: string) => {
  return api.post('/auth/register', {
    login: username,
    password,
  }) as Promise<RegisterResponse>;
};

export const logout = async (all?: boolean) => {
  LocalStorage.removeAllData();
  await api.post('/auth/logout', {
    // did: userAgent,
    all: all || false,
  });
};

export const getUserAgent = () => {
  return navigator.userAgent;
};

export const getProviderCredentialCallback = (
  provider: string,
  code?: string,
) => {
  return api.get(`/${provider}/seller/redirect?code=${code}`);
};

export const refreshToken = () => {
  return api.post('/auth/refresh');
};

export const clearToken = async () => {
  LocalStorage.removeAllData();
  return api.post('/auth/clearauth');
};

export const runSpecificRequest = (resolve: any, request: any) => {
  return resolve(api(request));
};

export const checkIsAu = () => {
  if (typeof document === 'undefined') return false;
  return document.cookie.includes('isAu=true');
};
