import { UserRole } from './common';
import AppRoutes from './routes';

// Role requirement ticket
// https://github.com/Tripical/FRONTEND___seller/issues/164

export const publicRoutes = [
  AppRoutes.DASHBOARD.index,
  AppRoutes.TRIPMANAGEMENT.index,
  AppRoutes.TRIPMANAGEMENT.list,
  AppRoutes.SUPPORT.index,
  AppRoutes.TOOLS.index,
];

// No Role
// roles array length = 0, thì block global
// ko có roles, mở menu account, chat, hỗ trợ
export const noRoleRoutes = [
  AppRoutes.ACCOUNT.index,
  AppRoutes.ACCOUNT.setting,
  AppRoutes.CHAT.index,
  AppRoutes.SUPPORT.index,
];

// Tripper role
// yêu cầu roles Tripper cho:
// chat, tạo trip của tôi, tài khỏan
// Page trip, nếu có tripper thì passed trang,
export const tripperRoutes = [
  AppRoutes.CHAT.index,
  AppRoutes.ACCOUNT.setting,
  AppRoutes.TRIPMANAGEMENT.list,
  AppRoutes.TRIPMANAGEMENT.create,
  AppRoutes.TRIPMANAGEMENT.update,
];

// yêu cầu roles "trip maker, trip designer, tour operator, moderate, admin , super admin" cho:
// menu tài chỉnh, đơn hàng, danh sách đánh giá, quản lý hỏi đáp
// tab trip chờ duyệt, trip đang bán
// menu gửi duyệt trip
// tab duyệt, tab đăng bán, nếu có trip maker hoặc trip designer hoặc tour operator, thì passed tab
export const builderRoutes = [
  AppRoutes.FINANCE.statement,

  AppRoutes.ORDER.tripsaleform,
  AppRoutes.ORDER.disputeDetail,
  AppRoutes.ORDER.dispute,

  AppRoutes.TRIPMANAGEMENT.list,
  AppRoutes.TRIPMANAGEMENT.reviews,
  AppRoutes.TRIPMANAGEMENT.qna,
];

export const allRoles = [
  UserRole.ADMIN,
  UserRole.MODERATE,
  UserRole.SUPER_ADMIN,
  UserRole.TOUR_OPERATOR,
  UserRole.TRIPPER,
  UserRole.TRIP_DESIGNER,
  UserRole.TRIP_MAKER,
];
