import { CustomFileUpload, UploadStatus } from 'hooks/useUploadImage';
import { Message } from 'models/message';
import { VN_BAD_WORDS } from '../bad-words';

import { MAX_UPLOAD_SIZE, OrderStatus } from 'constants/common';
import env from 'constants/env';
import { PageNameList } from 'constants/routes';
import { errors } from 'constants/validation';
import {
  IGetSalesByTimeRangeResponse,
  sendingTimeFormat,
} from 'models/statistics';
import moment from 'moment';
import { MODAL_CONFIRM_CLOSE_BTN_ONLY_OPTIONS } from 'components/CustomAntd/Configs/modal';

export function getAvatarPath({ path, isGroup, isThumb }) {
  if (isGroup) {
    return '/static/images/group_user.png';
  }

  if (!isGroup && !path) {
    return '/static/images/circle_tripical_logo.png';
  }

  return fixImgPath(path, isThumb);
}

export function fixImgPath(path: string, isThumb?: boolean) {
  if (
    path?.includes('http') ||
    path?.includes('file') ||
    path?.includes('base64')
  )
    return path;
  else {
    const newPath = path[0] !== '/' ? `/${path}` : path;
    return (!isThumb ? env.imageDomain : env.thumbImageDomain) + newPath;
  }
}

export const removeImgPath = (path: string, isThumb?: boolean) =>
  path.replace(
    !isThumb ? env.imageDomain || '' : env.thumbImageDomain || '',
    '',
  );

export function sortConversations(a: any, b: any) {
  return (
    new Date(b.message?.createdAt || b.createdAt).valueOf() -
    new Date(a.message?.createdAt || a.createdAt).valueOf()
  );
}

export const mapMessageData = (messages: Message[]): any[] => {
  return messages.map((msg) => ({
    ...msg,
    ...(msg.image
      ? {
          image: {
            main: fixImgPath(msg?.image.main),
            thumb: msg?.image.thumb && fixImgPath(msg?.image.thumb, true),
          },
        }
      : {}),
    ...(msg.video ? { video: fixImgPath(msg?.video) } : {}),
    ...(msg.audio ? { audio: fixImgPath(msg?.audio) } : {}),
    ...(msg.location
      ? {
          location: msg.location.latitude
            ? msg.location
            : {
                latitude: msg.location.split(',')[0],
                longitude: msg.location.split(',')[1],
              },
        }
      : {}),
    user: {
      ...msg.user,
      ...(msg?.user?.avatar ? { avatar: fixImgPath(msg?.user?.avatar) } : {}),
      _id: msg.user.uid,
      name: msg.user.displayName,
    },
  }));
};

export const capitalizeFirstLetter = (value: string): string =>
  value
    .trim()
    .split('')
    .map((val, idx) =>
      idx === 0 || value[idx - 1] === ' ' ? val.toUpperCase() : val,
    )
    .join('');

export const dataURItoBlob = (dataURI) => {
  const byteString = window.atob(dataURI.split(',')[1]);

  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

export const checkValidFileSize = (file, modal, maxSize = MAX_UPLOAD_SIZE) => {
  if (file.size >= maxSize) {
    modal.confirm({
      ...MODAL_CONFIRM_CLOSE_BTN_ONLY_OPTIONS,
      content: errors.common.OUT_OF_FILE_SIZE,
    });
    return false;
  }

  return true;
};

export const checkBadWords = (text = '') => {
  const words = text.split(' ');
  const badWords = new RegExp(VN_BAD_WORDS.join('|'), 'gi');

  const newWords: any[] = [];
  for (let i = 0; i < words.length; i++) {
    if (badWords.test(words[i])) {
      newWords.push(words[i].replace(/./g, '*'));
    } else {
      newWords.push(words[i]);
    }
  }
  return newWords.join(' ');
};

export const getDistanceFromRoute = (route: any) => {
  let count = 0;
  const routes = route.routes;
  for (const routeItem of routes) {
    const lengths = routeItem.sections.map((v: any) =>
      typeof v.summary.length === 'string'
        ? Number(v.summary.length)
        : v.summary.length,
    );
    const sum = lengths.reduce(function (
      previousValue: number,
      currentValue: number,
    ) {
      return previousValue + currentValue;
    });
    count += sum;
  }
  return count;
};

interface GetStatusOrderOption {
  refunded: boolean;
  paid: boolean;
}

export const getStatusOrder = ({ refunded, paid }: GetStatusOrderOption) => {
  if (refunded) return OrderStatus.REFUNDED;
  if (paid) return OrderStatus.PAID;
  return OrderStatus.UNPAID;
};

export const isNum = (value: string) => {
  return /^\d*\.?\d*$/.test(value);
};

export const getBase64StringFromDataURL = (dataURL: string): string =>
  dataURL.replace('data:', '').replace(/^.+,/, '');

export const base64regex =
  /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

export const checkUploadedImages = (values: CustomFileUpload[]) => {
  for (const value of values) {
    if (
      (value.status && value.status !== UploadStatus.Done) ||
      !value.url ||
      base64regex.test(getBase64StringFromDataURL(value.url))
    ) {
      return false;
    }
  }

  return true;
};

export const isEqual = (obj1, obj2) => {
  if (!obj1 || !obj2) return true;
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
      // console.log('changed at:', key, obj1[key], obj2[key]);
      return false;
    }
  }

  return true;
};

export const generateDayInRangeWithInitAmount = (
  startDate: string,
  endDate: string,
  expectedUiFormat: string, // format muốn render ra UI
  datesInRangeFromServer: IGetSalesByTimeRangeResponse[],
): IGetSalesByTimeRangeResponse[] => {
  const backendFormat = 'YYYY-MM-DD'; // format từ backend (response interface date backend)
  const datesInRange: IGetSalesByTimeRangeResponse[] = [];
  const localStartDate = moment.utc(startDate, sendingTimeFormat).local();

  while (localStartDate.isSameOrBefore(endDate, 'day')) {
    const localDataDate = localStartDate.format(expectedUiFormat);

    const matchingDate = datesInRangeFromServer.find(
      (item) =>
        moment(item?.date?.value, backendFormat).format(expectedUiFormat) ===
        localDataDate,
    );

    let dateToAdd: IGetSalesByTimeRangeResponse;
    if (matchingDate) {
      dateToAdd = {
        date: {
          value: moment(matchingDate.date.value, backendFormat).format(
            expectedUiFormat,
          ),
        },
        amount: matchingDate.amount,
        count: matchingDate.count,
      };
    } else {
      dateToAdd = { date: { value: localDataDate }, amount: 0, count: 0 };
    }

    datesInRange.push(dateToAdd);

    localStartDate.add(1, 'day');
  }

  return datesInRange;
};

export function getWeeksFrom(
  skip = 0,
  take = 10,
): { data: { from: Date; to: Date }[]; total: number } {
  const now = new Date();
  // const now = new Date(2023, 10, 19, 2); // test case Sunday Nov 19th, 2023
  const start = new Date(2023, 4, 1); // May 1st, 2023
  const weekArrays: { from: Date; to: Date }[] = [];

  for (let i = start; i <= now; ) {
    const from = new Date(i.getTime());
    const to = new Date(from.getTime());
    to.setDate(to.getDate() + 6);

    // Check if 'to' is in the future or if 'to' is today but today is not Sunday
    if (
      to > now ||
      (to.toDateString() === now.toDateString() && now.getDay() === 0)
    ) {
      break;
    }

    weekArrays.push({ from, to });
    i.setDate(i.getDate() + 7);
  }

  weekArrays.reverse(); // Reverse to have latest weeks on top

  // Pagination
  return {
    data: weekArrays.slice(skip, skip + take),
    total: weekArrays.length,
  };
}

export const getPageByPath = (pathname: string) => {
  const page = PageNameList.find((v) => v.path.includes(pathname));
  return page;
};

export function isTimeXLaterThanTimeY(timeX: string, timeY: string) {
  const dateA = new Date(`2000-01-01 ${timeX}`);
  const dateB = new Date(`2000-01-01 ${timeY}`);
  return dateA.getTime() >= dateB.getTime();
}

export function compareDateTime({
  current,
  next,
  prev,
}: {
  current: { date: string; time: string };
  next: { date: string; time: string };
  prev: { date: string; time: string };
}) {
  if (
    current.date > next.date ||
    (current.date === next.date &&
      isTimeXLaterThanTimeY(current.time, next.time)) ||
    current.date < prev.date ||
    (current.date === prev.date &&
      isTimeXLaterThanTimeY(prev.time, current.time))
  ) {
    return false;
  }
  return true;
}

export function randomizeCoordinates(lat, lng, radiusKm = 5) {
  const radiusInDegrees = radiusKm / 111;
  const randomLat = (Math.random() - 0.5) * radiusInDegrees * 2;
  const randomLng =
    ((Math.random() - 0.5) * radiusInDegrees * 2) /
    Math.cos((parseFloat(lat) * Math.PI) / 180);

  return {
    lat: parseFloat(lat) + randomLat,
    lng: parseFloat(lng) + randomLng,
  };
}
