import { Point } from 'models/product';
import upperFirst from 'lodash/upperFirst';
import toLower from 'lodash/toLower';
import moment from 'moment';
import { removeImgPath } from './common/helpers';
import env from 'constants/env';
import { BuildURLType } from 'constants/common';

export const formatCategoryData = (fromData: any[] = []) => {
  const newData = fromData
    .filter((v) => v.parent === '/')
    .map((v) => {
      let childrens = fromData.filter((c) => c.parent === v.category);
      childrens = childrens.map((a) => ({
        code: a.category,
        name: a.description.vi,
      }));
      return {
        code: v.category,
        name: v.description.vi,
        items: childrens,
      };
    });
  return newData;
};

export const formatCheckpoints = (checkpoints: any[] = []): Point[] => {
  const newData = [...checkpoints].map((v) => ({
    name: v.address,
    type: v.checkpointType,
    content: v.content,
    geo: {
      lon: v.coor.lng,
      lat: v.coor.lat,
    },
    location: v.address,
    images: v.images.map((img: any) => removeImgPath(img.url)),
    thumbs: v.images.map((img: any) => removeImgPath(img.thumbUrl)),
    time: {
      at: v.time,
      day: Number(v.date),
    },
    direction: v.createRoute,
  }));
  return newData as Point[];
};

export const formatCurrency = (money: number | string) => {
  return Number(money).toLocaleString('it-IT');
};

export const formatDate = (
  date: string | Date,
  format: string,
  currentFormat?: string,
) => {
  return moment(date, currentFormat).format(format);
};

export const sentenceString = (text: string) => {
  return upperFirst(toLower(text));
};

export const buildUrl = (type: BuildURLType, data: any) => {
  if (type === BuildURLType.TRIP_DETAIL)
    return `${env.portalAppHost}/trips/:id`.replace(
      ':id',
      `${data.name.split(' ').join('-').replace(/\?/g, '')}-${data.sku}`,
    );

  if (type === BuildURLType.MY_TRIP_DETAIL)
    return `${env.portalAppHost}/my-trips/:id`.replace(
      ':id',
      `${data.name.split(' ').join('-').replace(/\?/g, '')}-${data.sku}`,
    );

  return '';
};

export const formatPhoneNumber = (phone: string, country = '+84') => {
  if (phone.startsWith('0')) {
    phone = phone.slice(1);
  }
  return country + phone;
};

export const formatUsername = (phoneOrEmail: string, country?: string) => {
  return phoneOrEmail.includes('@')
    ? phoneOrEmail
    : formatPhoneNumber(phoneOrEmail, country);
};

export const formatValue = (
  value: string | number | undefined,
): string | number => {
  if (typeof value !== 'undefined') {
    return value;
  }
  return 'Lỗi';
};

export const formatUTF8 = (value?: string) => {
  if (typeof value !== 'string') return;
  value = value.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  value = value.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  value = value.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  value = value.replace(/o|ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  value = value.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  value = value.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  value = value.replace(/đ|d/g, 'd');
  value = value.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  value = value.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư

  return value;
};

export const formatRole = (roles: string[]) => {
  if (!roles?.length) return '';
  return sentenceCase(roles[roles.length - 1]?.split('_').join(' ') || '');
};
export const sentenceCase = (text: string) => {
  return upperFirst(toLower(text));
};

export enum DateFormat {
  HOUR_YEAR_MONTH_DATE = 'HH:mm DD/MM/YYYY',
  ISO = 'YYYY-MM-DDTHH:mm:ss.sss[Z]',
  TIME = 'HH:mm',
}
