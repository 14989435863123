import { ConfigProvider, ThemeConfig } from 'antd';
import useSwitchThemeMode from 'hooks/useSwitchThemeMode';
import React from 'react';
import { GLOBAL_COLOR } from './color';

export const HEADER_HEIGHT = 64;
export const FOOTER_HEIGHT = 80;
export const SIDER_WIDTH = 250;
export const SIDER_COLLAPSED_WIDTH = 80;
const ThemeProvider = ({ children }: React.PropsWithChildren) => {
  const { themeMode, ThemeModeColor } = useSwitchThemeMode();
  const lightMode = themeMode === ThemeModeColor.LIGHT;
  // DARK/LIGHT mode sẽ được setup với antd token và thư mục customAntd
  // src/components/CustomAntd (Button, Card)
  const AntTheme: ThemeConfig = {
    token: {
      fontFamily: 'SVN-Gilroy',
      fontSize: 16,
      motionDurationSlow: '0.1s',
      controlOutlineWidth: 0,
      colorPrimary: GLOBAL_COLOR.ORANGE_400,
    },
    components: {
      Layout: {
        siderBg: GLOBAL_COLOR.GREY_900,
        bodyBg: lightMode ? GLOBAL_COLOR.GREY_100 : GLOBAL_COLOR.DARK,
        headerBg: lightMode ? GLOBAL_COLOR.GREY_100 : GLOBAL_COLOR.DARK,
      },
      Menu: {
        itemSelectedBg: GLOBAL_COLOR.ORANGE_400,
        itemBg: GLOBAL_COLOR.GREY_900,
        itemColor: GLOBAL_COLOR.GRAY_600,
        itemHoverBg: GLOBAL_COLOR.ORANGE_LIGHT,
        itemSelectedColor: GLOBAL_COLOR.WHITE,
        subMenuItemBg: GLOBAL_COLOR.WHITE,
        itemHeight: 56,
        itemBorderRadius: 0,
        itemMarginInline: 0,
        itemMarginBlock: 0,
      },
      Button: {
        colorPrimary: GLOBAL_COLOR.ORANGE_400,
        colorText: GLOBAL_COLOR.ORANGE_400,
        colorPrimaryHover: GLOBAL_COLOR.ORANGE_LOW,
        defaultBorderColor: GLOBAL_COLOR.ORANGE_400,
        defaultColor: GLOBAL_COLOR.ORANGE_400,
        defaultHoverBorderColor: GLOBAL_COLOR.ORANGE_400,
        defaultHoverColor: GLOBAL_COLOR.ORANGE_LOW,
        colorTextDisabled: GLOBAL_COLOR.GREY_700,
        colorBgContainerDisabled: GLOBAL_COLOR.GREY_100,
        controlHeight: 43,
        controlHeightLG: 52,
        borderRadius: 8,
        fontWeight: 500,
        fontSize: 16,
      },
      Card: {
        colorBgContainer: lightMode
          ? GLOBAL_COLOR.WHITE
          : GLOBAL_COLOR.DARK_100,
        colorBorderSecondary: GLOBAL_COLOR.DIVIDER_WHITE,
        colorText: lightMode ? GLOBAL_COLOR.DARK_BLUE : GLOBAL_COLOR.WHITE,
      },
      Input: {
        colorText: lightMode
          ? GLOBAL_COLOR.DARK_BLUE
          : GLOBAL_COLOR.WHITE_LIGHT,
        colorTextPlaceholder: lightMode
          ? GLOBAL_COLOR.GREY_700
          : GLOBAL_COLOR.WHITE_LIGHT,
        colorBgContainer: lightMode
          ? GLOBAL_COLOR.WHITE
          : GLOBAL_COLOR.DARK_200,
        colorTextDescription: lightMode
          ? GLOBAL_COLOR.DARK
          : GLOBAL_COLOR.DARK_300,
        hoverBorderColor: GLOBAL_COLOR.ORANGE_400,
        activeBorderColor: GLOBAL_COLOR.ORANGE_400,
        colorBorder: lightMode ? GLOBAL_COLOR.GREY_300 : GLOBAL_COLOR.DARK_300,
        activeShadow: GLOBAL_COLOR.SHADOW_ORANGE,
        // INPUT DISABLED
        colorBgContainerDisabled: lightMode
          ? GLOBAL_COLOR.WHITE_OPACITY_04
          : GLOBAL_COLOR.DARK,
        colorTextDisabled: lightMode
          ? GLOBAL_COLOR.WHITE_OPACITY_025
          : GLOBAL_COLOR.DARK_300,
        controlHeight: 44,
        controlHeightLG: 52,
        borderRadius: 8,
        inputFontSize: 14,
        inputFontSizeSM: 14,
        inputFontSizeLG: 16,
      },
      InputNumber: {
        colorText: lightMode
          ? GLOBAL_COLOR.DARK_BLUE
          : GLOBAL_COLOR.WHITE_LIGHT,
        colorTextPlaceholder: lightMode
          ? GLOBAL_COLOR.GREY_700
          : GLOBAL_COLOR.WHITE_LIGHT,
        colorBgContainer: lightMode
          ? GLOBAL_COLOR.WHITE
          : GLOBAL_COLOR.DARK_200,
        hoverBorderColor: GLOBAL_COLOR.ORANGE_400,
        activeBorderColor: GLOBAL_COLOR.ORANGE_400,
        colorBorder: lightMode ? GLOBAL_COLOR.GREY_300 : GLOBAL_COLOR.DARK_300,
        activeShadow: GLOBAL_COLOR.SHADOW_ORANGE,
        controlHeight: 44,
        controlHeightLG: 52,
        borderRadius: 8,
        inputFontSize: 14,
        inputFontSizeSM: 14,
        inputFontSizeLG: 16,
      },
      DatePicker: {
        colorText: lightMode
          ? GLOBAL_COLOR.DARK_BLUE
          : GLOBAL_COLOR.WHITE_LIGHT,
        colorTextPlaceholder: lightMode
          ? GLOBAL_COLOR.GREY_700
          : GLOBAL_COLOR.WHITE_LIGHT,
        colorBgContainer: lightMode
          ? GLOBAL_COLOR.WHITE
          : GLOBAL_COLOR.DARK_200,
        //BG color cho cái dropdown
        colorBgElevated: lightMode ? GLOBAL_COLOR.WHITE : GLOBAL_COLOR.DARK_100,
        hoverBorderColor: GLOBAL_COLOR.ORANGE_400,
        activeBorderColor: GLOBAL_COLOR.ORANGE_400,
        colorBorder: lightMode ? GLOBAL_COLOR.GREY_300 : GLOBAL_COLOR.DARK_300,
        activeShadow: GLOBAL_COLOR.SHADOW_ORANGE,
        controlHeight: 44,
        fontSize: 14,
      },
      Select: {
        colorText: lightMode
          ? GLOBAL_COLOR.DARK_BLUE
          : GLOBAL_COLOR.WHITE_LIGHT,
        colorTextPlaceholder: lightMode
          ? GLOBAL_COLOR.GREY_700
          : GLOBAL_COLOR.WHITE_LIGHT,
        colorBgContainer: lightMode
          ? GLOBAL_COLOR.WHITE
          : GLOBAL_COLOR.DARK_200,
        //BG color cho cái dropdown
        colorBgElevated: lightMode ? GLOBAL_COLOR.WHITE : GLOBAL_COLOR.DARK_100,
        optionSelectedBg: lightMode
          ? GLOBAL_COLOR.WHITE_LIGHT
          : GLOBAL_COLOR.DARK_200,
        colorBorder: lightMode ? GLOBAL_COLOR.GREY_300 : GLOBAL_COLOR.DARK_300,
        // Icon color
        colorTextQuaternary: lightMode
          ? GLOBAL_COLOR.DARK
          : GLOBAL_COLOR.DARK_300,
        colorPrimaryHover: GLOBAL_COLOR.ORANGE_400,
        colorPrimary: GLOBAL_COLOR.ORANGE_400,
        colorPrimaryBorder: GLOBAL_COLOR.ORANGE_400,
        boxShadow: GLOBAL_COLOR.SHADOW_ORANGE,
        controlHeightLG: 52,
        controlHeight: 44,
        fontSize: 14,
        fontSizeLG: 16,
      },
      Cascader: {
        colorBorder: lightMode ? GLOBAL_COLOR.GREY_300 : GLOBAL_COLOR.DARK_300,
        dropdownHeight: 448,
        controlItemWidth: 130,
        optionPadding: '8px 12px',
        // Icon color
        colorTextDescription: lightMode
          ? GLOBAL_COLOR.DARK
          : GLOBAL_COLOR.DARK_300,
        controlHeight: 44,
        borderRadius: 8,
      },
      Divider: {
        colorSplit: lightMode
          ? GLOBAL_COLOR.DIVIDER_WHITE
          : GLOBAL_COLOR.DIVIDER_DARK,
        margin: 8,
        marginLG: 8,
      },
      Typography: {
        colorBorderSecondary: GLOBAL_COLOR.DIVIDER_WHITE,
        colorTextSecondary: GLOBAL_COLOR.ORANGE_400,
        colorTextTertiary: GLOBAL_COLOR.ORANGE_400,
        colorTextDescription: GLOBAL_COLOR.ORANGE_400,
        colorText: lightMode ? GLOBAL_COLOR.BLACK : GLOBAL_COLOR.WHITE,
        colorLink: lightMode ? GLOBAL_COLOR.BLACK : GLOBAL_COLOR.WHITE,
        colorTextHeading: lightMode ? GLOBAL_COLOR.BLACK : GLOBAL_COLOR.WHITE,
        fontSizeHeading2: 32,
        fontSize: 14,
        titleMarginTop: 0,
        titleMarginBottom: 0,
        margin: 0,
      },
      Popover: {
        colorBgBase: GLOBAL_COLOR.WHITE,
        colorBgContainer: GLOBAL_COLOR.WHITE,
        colorText: GLOBAL_COLOR.BLACK,
        colorBgElevated: GLOBAL_COLOR.WHITE,
      },
      List: {
        controlHeight: 32,
      },
      Dropdown: {
        colorBgContainer: lightMode
          ? GLOBAL_COLOR.WHITE
          : GLOBAL_COLOR.DARK_200,
        colorText: lightMode ? GLOBAL_COLOR.BLACK : GLOBAL_COLOR.WHITE_LIGHT,
        colorBgElevated: lightMode ? GLOBAL_COLOR.WHITE : GLOBAL_COLOR.DARK_100,
        controlItemBgHover: lightMode
          ? GLOBAL_COLOR.WHITE_LIGHT
          : GLOBAL_COLOR.DARK_200,
      },
      Checkbox: {
        colorPrimary: GLOBAL_COLOR.ORANGE_400,
        colorPrimaryBorder: GLOBAL_COLOR.ORANGE_400,
        colorPrimaryHover: GLOBAL_COLOR.ORANGE_400,
      },
      Spin: {
        colorPrimary: GLOBAL_COLOR.ORANGE_400,
      },
      Tabs: {
        itemSelectedColor: GLOBAL_COLOR.ORANGE_400,
        inkBarColor: GLOBAL_COLOR.ORANGE_400,
        itemHoverColor: GLOBAL_COLOR.ORANGE_400,
      },
      Upload: {
        colorPrimary: GLOBAL_COLOR.GREY_300,
        colorPrimaryBorder: GLOBAL_COLOR.GREY_300,
      },
      Collapse: {
        headerBg: lightMode ? GLOBAL_COLOR.GRAY_200 : GLOBAL_COLOR.GRAY_200,
        colorTextHeading: lightMode
          ? GLOBAL_COLOR.DARK_BLUE
          : GLOBAL_COLOR.WHITE_LIGHT,
        colorBorder: GLOBAL_COLOR.BLACK,
        contentBg: GLOBAL_COLOR.GRAY_200,
        headerPadding: 0,
        contentPadding: 0,
      },
      Badge: {
        fontSize: 16,
        lineHeight: 1.375,
      },
      Table: {
        colorBgContainer: lightMode
          ? GLOBAL_COLOR.WHITE
          : GLOBAL_COLOR.DARK_200,
        headerBg: lightMode ? GLOBAL_COLOR.WHITE : GLOBAL_COLOR.DARK_200,
      },
      Modal: {
        contentBg: lightMode ? GLOBAL_COLOR.WHITE : GLOBAL_COLOR.DARK_200,
        headerBg: lightMode ? GLOBAL_COLOR.WHITE : GLOBAL_COLOR.DARK_200,
        footerBg: lightMode ? GLOBAL_COLOR.WHITE : GLOBAL_COLOR.DARK_200,
        titleColor: lightMode ? GLOBAL_COLOR.DARK_BLUE : GLOBAL_COLOR.WHITE,
        colorText: lightMode ? GLOBAL_COLOR.DARK_BLUE : GLOBAL_COLOR.WHITE,
      },
      Tooltip: {
        colorBgSpotlight: lightMode
          ? GLOBAL_COLOR.DARK_100
          : GLOBAL_COLOR.DARK_300,
      },
      Form: {
        controlHeight: 44,
      },
    },
  };

  return <ConfigProvider theme={AntTheme}>{children}</ConfigProvider>;
};

export default ThemeProvider;
