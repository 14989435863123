import TitleWrapper from 'components/Wrapper/TitleWrapper';
import { UserRole } from 'constants/common';
import AppRoutes from 'constants/routes';
import useCheckRoleAccessPage from 'hooks/useCheckRoleAccessPage';
import PageProtection from 'sections/PageProtection';
import { AccountInfo } from './AccountInfo';
import PageContainer from 'components/Wrapper/PageContainer';

export function AccountSetting() {
  const { validAccess, page } = useCheckRoleAccessPage(
    [UserRole.TRIPPER],
    AppRoutes.ACCOUNT.setting,
  );

  if (!validAccess) {
    return <PageProtection title={page?.name} />;
  }
  return (
    <PageContainer>
      <TitleWrapper
        column={'Common:account.title'}
        tab={'Common:account.setting'}
      />
      <AccountInfo />
    </PageContainer>
  );
}
