import Common from './common';
import Sider from './sider';
import Validation from './validation';

const vi = {
  Common,
  Sider,
  Validation,
};

export default vi;
