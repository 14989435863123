import { App, Button, Divider, Flex, Input, Switch, Upload } from 'antd';
import { ArrowNextIcon, EditIcon } from 'assets/icons';
import NoAvatar from 'assets/images/no_avatar.png';
import CustomCard from 'components/CustomAntd/CustomCard';
import CustomLoadingImage from 'components/Loading/CustomLoadingImage';
import ChangePasswordModal from 'components/Modals/ChangePasswordModal';
import SectionTitle from 'components/SharedLayout/SectionTitle';
import env from 'constants/env';
import { messages } from 'constants/validation';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxStore';
import { useUploadImage } from 'hooks/useUploadImage';
import { updateMyProfile } from 'libs/api/user';
import { useState } from 'react';
import { updateProfile } from 'stores/authSlice';
import { selectProfile } from 'stores/selectors';
import { fixImgPath } from 'utils/common/helpers';
import { sentenceString } from 'utils/format';

const { Dragger } = Upload;

export function AccountInfo() {
  const { notification } = App.useApp();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);
  const [editName, setEditName] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [displayName, setDisplayName] = useState('');

  const { draggerDefaultProps, isLoading } = useUploadImage({
    prefix: 'user/avatar',
    onImageList: (images) => {
      if (!images?.length) return;
      handleUpdateDisplayName('avatar', images[0].thumbUrl);
    },
    multiple: false,
  });
  const handleUpdateDisplayName = async (
    type: 'displayName' | 'avatar',
    data: string,
  ) => {
    if (data.includes('base64')) return;
    const params = {
      [type]: data,
    };
    try {
      await updateMyProfile({ displayName: profile.displayName, ...params });
      dispatch(
        updateProfile({
          ...profile,
          ...params,
        }),
      );
      notification.success({ message: messages.UPDATE_SUCCESS });
    } catch (error) {
      notification.error({ message: messages.UPDATE_FAILED });

      if (type === 'displayName') {
        setDisplayName(profile.displayName);
      }
    } finally {
      setEditName(false);
    }
  };

  const avatar = profile.avatar ? fixImgPath(profile.avatar, true) : NoAvatar;

  return (
    <div className="w-full">
      <Flex vertical align="center" gap={16}>
        <CustomCard
          className="w-full"
          classNames={{
            body: 'w-full rounded-xl shadow-card p-2',
          }}
        >
          <Flex align="center" justify="center">
            <div className="flex space-x-4 items-center my-4">
              <div className="relative rounded-full">
                <Dragger
                  {...draggerDefaultProps}
                  className="customAvatarAccount"
                >
                  <CustomLoadingImage
                    src={avatar}
                    loading={isLoading}
                    className="h-40 w-40 rounded-full"
                  />
                </Dragger>
              </div>
              <Flex vertical gap={8}>
                <div className="flex items-start gap-x-2">
                  {editName ? (
                    <Input
                      type="text"
                      size="small"
                      defaultValue={profile.displayName}
                      onChange={(e) => setDisplayName(e.target.value)}
                    />
                  ) : (
                    <div className="text-2xl font-semibold ">
                      {profile.displayName}
                    </div>
                  )}
                  <div className="flex items-center space-x-2">
                    {editName ? (
                      <>
                        <Button
                          size="small"
                          type="primary"
                          onClick={() =>
                            handleUpdateDisplayName('displayName', displayName)
                          }
                        >
                          Lưu
                        </Button>
                        <Button
                          size="small"
                          onClick={() => {
                            setDisplayName(profile.displayName);
                            setEditName(!editName);
                          }}
                        >
                          Huỷ
                        </Button>
                      </>
                    ) : (
                      <EditIcon
                        className="cursor-pointer "
                        onClick={() => setEditName(!editName)}
                      />
                    )}
                  </div>
                </div>
                <div className="bg-[#fd974b] text-white rounded-full px-3 py-1 w-fit">
                  {profile.roles
                    .map((v) => {
                      const role = v.split('_').join(' ');
                      return sentenceString(role);
                    })
                    .slice(-1)}
                </div>
                <div className="text-gray-400">
                  {profile.location || profile.address
                    ? `${profile.address?.prov}, ${profile.address?.ctry}`
                    : 'N/A'}
                </div>
              </Flex>
            </div>
          </Flex>
        </CustomCard>

        <a
          href={`${env.portalAppHost}/account/upgrade`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary flex items-center space-x-2 hover:underline font-semibold"
        >
          <span>Nâng cấp tài khoản người bán</span>
          <ArrowNextIcon />
        </a>
        <div className=" font-semibold">Tripper có thể tạo trip cá nhân</div>
        <div className=" font-semibold">Trip Maker có thể đăng bán</div>
        <div className=" font-semibold">
          Trip Designer có thể thiết kế trip theo yêu cầu
        </div>
      </Flex>

      <SectionTitle className=" mt-8">Cài đặt khác</SectionTitle>
      <Flex vertical gap={24}>
        <Flex align="center" justify="space-between" className="p-2">
          <span className="text-lg font-semibold ">Thông báo email</span>
          <Switch />
        </Flex>
        <Divider className="m-0" />
        <Flex align="center" justify="space-between" className="p-2">
          <Flex vertical>
            <div className="text-lg font-semibold ">Bảo mật</div>
            <input
              className="p-0  border-none outline-none font-semibold text-base bg-transparent cursor-not-allowed"
              type="password"
              defaultValue="1234567890"
              disabled={true}
            />
          </Flex>

          <div onClick={() => setShowPasswordModal(true)}>
            <EditIcon className="cursor-pointer" />
          </div>
        </Flex>
        <Divider className="m-0" />
        <Flex className="py-6" vertical>
          <div className="font-semibold ">Tài khoản ngân hàng</div>
          <a
            href={`${env.portalAppHost}/account/banks`}
            target="_blank"
            rel="noopener noreferrer nofollow"
            className="text-primary flex items-center space-x-2 hover:underline"
          >
            <span>Cập nhật tài khoản ngân hàng</span>
            <ArrowNextIcon />
          </a>
          <div className="text-sm ">
            Được sử dụng để nhận doanh thu bán Trips
          </div>
        </Flex>
      </Flex>

      <ChangePasswordModal
        open={showPasswordModal}
        onCancel={() => setShowPasswordModal(false)}
      />
    </div>
  );
}
