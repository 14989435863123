import { Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
const { Title, Text } = Typography;
interface TitleWrapperProps {
  title?: string;
  column?: string;
  tab?: string;
  titleOnly?: boolean;
}

const TitleWrapper: React.FC<TitleWrapperProps> = ({
  title,
  column,
  tab,
  titleOnly = false,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mb-4">
      <Title level={3}>{t(title || column || '')}</Title>
      {!titleOnly && (
        <Flex align="center" gap={12}>
          <Text>{t(title || column || '')}</Text>
          <div className="h-1 w-1 bg-gray-600 rounded-full " />
          <Text type="secondary">{t(tab || '')}</Text>
        </Flex>
      )}
    </div>
  );
};

export default TitleWrapper;
