import { createContext, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Lightbox } from 'react-modal-image';
import { DisputeContextType } from 'models/dispute';
import useDisputeDetail from '../hooks/useDisputeDetail';
import useCheckRoleAccessPage from 'hooks/useCheckRoleAccessPage';
import { allRoles } from 'constants/permission';
import AppRoutes from 'constants/routes';
import PageProtection from 'sections/PageProtection';

const DisputeContext = createContext<DisputeContextType>({});

export const useDisputeContext = () => useContext(DisputeContext);

export const DisputeProvider = (props) => {
  const { children } = props;

  const { id: disputeId } = useParams();
  const [imagePreview, setImagePreview] = useState<string | null>();

  const {
    disputeDetailData: disputeData,
    loading: disputeLoading,
    refreshDispute,
  } = useDisputeDetail({
    id: disputeId as string,
  });

  const disputeDetailData = useMemo(
    () => ({
      data: disputeData,
      loading: disputeLoading,
    }),
    [disputeData, disputeLoading],
  );

  const { validAccess, page } = useCheckRoleAccessPage(
    allRoles,
    AppRoutes.ORDER.disputeDetail,
  );

  if (!validAccess) {
    return <PageProtection title={page?.name} />;
  }

  return (
    <DisputeContext.Provider
      value={{
        disputeDetailData,
        refreshDispute,
        setImagePreview,
      }}
    >
      {children}
      {imagePreview && (
        <Lightbox
          small={imagePreview}
          medium={imagePreview}
          large={imagePreview}
          alt=""
          onClose={() => setImagePreview(null)}
        />
      )}
    </DisputeContext.Provider>
  );
};
