import { LoadingOutlined } from '@ant-design/icons';
import { Image, Spin } from 'antd';

interface CustomImageProps {
  src: string;
  loading?: boolean;
  className?: string;
}

const CustomLoadingImage = ({
  src,
  loading = false,
  className,
}: CustomImageProps) => {
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {loading && (
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 30,
          }}
        />
      )}
      <Image
        className={className}
        src={src}
        alt=""
        preview={false}
        style={{
          filter: loading ? 'blur(2px)' : 'none',
          transition: 'filter 0.3s ease',
          objectFit: 'cover',
        }}
      />
    </div>
  );
};

export default CustomLoadingImage;
