import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'libs/redux/RootState';
import { initialState } from './authSlice';

const selectSlice = (state: RootState) => state.auth ?? initialState;

export const selectProfile = createSelector(
  [selectSlice],
  (state) => state.profile,
);
export const selectSessionExpired = createSelector(
  [selectSlice],
  (state) => state.sessionExpired,
);
