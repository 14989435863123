import { Card, Flex } from 'antd';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  className?: string;
};

const PageContainer = ({ children, className }: Props) => {
  return (
    <Card
      classNames={{
        body: 'p-0',
      }}
    >
      <Flex
        className={`px-6 pt-12 min-h-[calc(100vh-70px)] ${className}`}
        vertical
      >
        {children}
      </Flex>
    </Card>
  );
};

export default PageContainer;
