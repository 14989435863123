import { Result, Button, Flex } from 'antd';
import { Link } from 'react-router-dom';
import AppRoutes from 'constants/routes';
import { useTranslation } from 'react-i18next';
import BlackButton from 'components/CustomAntd/BlackButton';

interface ErrorBoundaryProps {
  code: 403 | 404;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ code }) => {
  const { t } = useTranslation();
  const title =
    code === 403 ? t('Common:accessDeniedCode') : t('Common:notFoundCode');
  const desc =
    code === 403 ? t('Common:accessDeniedDesc') : t('Common:notFoundCodeDesc');
  return (
    <Flex
      vertical
      justify="center"
      style={{ justifyContent: 'center', padding: '30px 15px 80px' }}
    >
      <Result
        status={code}
        title={title}
        subTitle={desc}
        extra={
          <Link to={AppRoutes.ROOT}>
            <BlackButton type="primary">{t('Common:backHome')}</BlackButton>
          </Link>
        }
        style={{ padding: 0 }}
      />
    </Flex>
  );
};

export default ErrorBoundary;
