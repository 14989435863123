import { Mutation, MutationCache, QueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { IError, MetaProps } from 'models/common';
import { authErrorMessages } from './error';

const handleError = async (
  error: IError,
  mutation?: Mutation<unknown, unknown, unknown, unknown>,
) => {
  // Error từ BE
  const errorMessage = error?.data?.message;

  if (mutation && mutation.meta) {
    // Flag để không show err BE
    // => Show lỗi predefined, nếu có
    const { noToastError }: MetaProps = mutation.meta;
    if (noToastError) return;
  }

  if (errorMessage?.includes(authErrorMessages.TOKEN_EXPIRED)) {
    // Không show lỗi token expired
    // đc xử lý ở layer api interceptor
    return;
  }

  if (errorMessage) {
    notification.error({ message: errorMessage });
  }
};
const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      structuralSharing: true,
      refetchOnWindowFocus: false,
      retry: false,
      suspense: false,
      networkMode: 'offlineFirst',
    },
    mutations: {
      networkMode: 'offlineFirst',
    },
  },
  // This function will be called if some mutation encounters an error.
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) =>
      handleError(error as IError, mutation),
  }),
});
export default queryClient;
