import logoFacebook from 'assets/images/facebook.png';
import logoGoogle from 'assets/images/google.png';
import schema, { LoginInputFields } from './schema';
import { Form, Formik, FormikConfig, FormikProps } from 'formik';
import { Button, Divider, Flex, Image, Typography } from 'antd';
import TextInput from 'components/Form/TextInput';
import PhoneSelect from 'components/PhoneSelect';
import CheckboxInput from 'components/Form/CheckboxInput';
import { OpenIcon } from 'assets/icons';
import env from 'constants/env';
import BlackButton from 'components/CustomAntd/BlackButton';
import { useTranslation } from 'react-i18next';
const { Text, Link } = Typography;
type Props = {
  onSubmit: (values: LoginInputFields) => void;
  handleSocialLogin: (provider: 'google' | 'facebook') => void;
  loading?: boolean;
};

const LoginForm = ({ onSubmit, handleSocialLogin, loading }: Props) => {
  const { t } = useTranslation();
  const formik: FormikConfig<LoginInputFields> = {
    initialValues: {
      phoneOrEmail: '',
      password: '',
      trust: true,
      country: {
        flag: '',
        name: '',
        phone: '',
      },
    },
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: schema(t),
    onSubmit: onSubmit,
  };

  return (
    <div className="mx-auto w-full">
      <Formik {...formik}>
        {({ setFieldValue }: FormikProps<LoginInputFields>) => (
          <Form className="flex flex-col w-full gap-y-1">
            <TextInput
              autoComplete="username"
              className="w-full"
              placeholder="Nhập số điện thoại hoặc email"
              classNames={{
                input: 'text-center font-medium',
              }}
              prefix={
                <PhoneSelect
                  onCountryPhoneChange={(data) =>
                    setFieldValue('country', data)
                  }
                />
              }
              name="phoneOrEmail"
              size="large"
              required
            />
            <TextInput
              autoComplete="password"
              className="w-full"
              placeholder="Mật khẩu"
              classNames={{
                input: 'text-center font-medium',
              }}
              required
              password
              size="large"
              name="password"
            />
            <BlackButton
              type="primary"
              loading={loading}
              size="large"
              htmlType="submit"
              className="w-full center font-semibold text-base rounded-2xl"
            >
              Đăng nhập
            </BlackButton>
            <CheckboxInput
              label="Lưu đăng nhập"
              name="trust"
              className="mx-auto w-fit mt-6  base-hover-effect"
            />
          </Form>
        )}
      </Formik>
      <Text>
        <Divider className="flex-1">Hoặc tiếp tục với</Divider>
      </Text>

      <Flex vertical gap={32} align="center" justify="center" className="mt-6">
        <BlackButton
          onClick={() => handleSocialLogin('facebook')}
          size="large"
          className="w-full center text-base base-hover-effect"
          icon={
            <Image
              src={logoFacebook}
              alt="logoFacebook"
              width={24}
              height={24}
              preview={false}
            />
          }
        >
          <Text className="text-base mt-1">Tiếp tục với Facebook</Text>
        </BlackButton>
        <BlackButton
          onClick={() => handleSocialLogin('google')}
          size="large"
          className="w-full center text-base base-hover-effect"
          icon={
            <Image
              src={logoGoogle}
              alt="logoGoogle"
              width={24}
              height={24}
              preview={false}
            />
          }
        >
          <Text className="text-base mt-1">Tiếp tục với Google</Text>
        </BlackButton>
        <Link
          className="flex items-center gap-1 underline text-black base-hover-effect"
          href={`${env.portalAppHost}/forgot-password`}
        >
          Quên mật khẩu <OpenIcon className="mb-1" />
        </Link>
        <Flex>
          <Text>Bạn mới biết đến Tripical?</Text>
          &nbsp;
          <Link
            className="text-primary underline base-hover-effect"
            href={`${env.portalAppHost}/login`}
          >
            Đăng ký
          </Link>
        </Flex>
      </Flex>
    </div>
  );
};

export default LoginForm;
