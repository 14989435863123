import { Layout as AntLayout, Spin } from 'antd';
import AppRoutes from 'constants/routes';
import { useAppSelector } from 'hooks/useReduxStore';
import { Outlet, useLocation } from 'react-router-dom';
import News from 'sections/News';
import { selectProfile } from 'stores/selectors';
import { selectSiderResponsiveOpen } from 'stores/themeSlice';
import { Header } from './Header';
import { CustomSider } from './Sider';

const { Content } = AntLayout;

const Layout = () => {
  const location = useLocation();
  const myProfile = useAppSelector(selectProfile);
  const isDashboard =
    location.pathname === AppRoutes.DASHBOARD.index ||
    location.pathname === AppRoutes.ROOT;
  const isSiderResponsiveOpen = useAppSelector(selectSiderResponsiveOpen);

  return (
    <AntLayout style={{ minHeight: '100vh' }}>
      <CustomSider />
      <AntLayout>
        <Header />
        <Spin tip="Loading" size="large" spinning={!myProfile?.uid}>
          <Content
            className={`w-full flex  ${isSiderResponsiveOpen ? 'hidden' : ''}`}
          >
            <div className="w-full">{<Outlet />}</div>
            {isDashboard && <News />}
          </Content>
        </Spin>
      </AntLayout>
    </AntLayout>
  );
};

export default Layout;
