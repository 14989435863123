/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { twMerge } from 'tailwind-merge';

interface PageBtnProps {
  content: React.ReactElement | number;
  onClick: () => void;
  active?: boolean;
  disabled?: boolean;
}

const PageBtn: React.FC<PageBtnProps> = ({
  content,
  onClick,
  active,
  disabled,
}) => {
  return (
    <button
      className={twMerge(
        `flex flex-col cursor-pointer items-center justify-center w-9 h-9 text-sm font-bold transition-colors rounded-lg border border-solid bg-white
        ${
          active
            ? 'bg-orange_grayish border border-orange-500 text-orange-500'
            : 'text-grey_select border border-gray-200'
        }
        ${
          disabled
            ? 'border border-gray-100 !text-gray-300 cursor-not-allowed'
            : 'hover:bg-orange-500 hover:text-white'
        }
      `,
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </button>
  );
};

interface PageListProps {
  pageIndex: number;
  pageIndices: number[];
  showLastPageJumping?: boolean;
  showFistPageJumping?: boolean;
  gotoPage: (pageIndex: number) => void;
}

const PageList: React.FC<PageListProps> = (props) => {
  const {
    pageIndices,
    showLastPageJumping = false,
    showFistPageJumping = false,
    pageIndex,
    gotoPage,
  } = props;

  const MorePageSign = () => (
    <li className="list-none">
      <div className="w-[1rem] flex justify-center items-end h-[100%] mx-2 cursor-default ">
        ...
      </div>
    </li>
  );

  return (
    <div className="flex gap-1">
      {pageIndices.map((pageIndexToMap, index) => (
        <React.Fragment key={pageIndexToMap}>
          {showLastPageJumping && index === pageIndices.length - 1 && (
            <MorePageSign />
          )}

          {showFistPageJumping && index === 1 && <MorePageSign />}

          <li className="list-none">
            <PageBtn
              content={pageIndexToMap + 1}
              onClick={() => gotoPage(pageIndexToMap)}
              active={pageIndex === pageIndexToMap}
            />
          </li>
        </React.Fragment>
      ))}
    </div>
  );
};

interface PaginationProps {
  gotoPage: (pageIndex: number) => any;
  total: number;
  pageSize: number;
  pageIndex: number;
  className?: string;
}

const Pagination: React.FC<PaginationProps> = (props) => {
  const VISIBLE_PAGE_BUTTON_COUNT = 3;

  const { gotoPage, total, pageSize, pageIndex, className = '' } = props;
  const pageCount = Math.ceil(total / pageSize);

  if (pageCount <= 1) {
    return null;
  }

  const renderPageLinks = useCallback(() => {
    let pageIndices: number[] = [];

    if (pageCount <= VISIBLE_PAGE_BUTTON_COUNT) {
      pageIndices = [...Array(pageCount)].map((_, index) => index);
      return (
        <PageList
          pageIndices={pageIndices}
          gotoPage={gotoPage}
          pageIndex={pageIndex}
          showLastPageJumping={false}
          showFistPageJumping={false}
        />
      );
    }

    const restPages = pageCount - (pageIndex + 1);
    if (restPages < VISIBLE_PAGE_BUTTON_COUNT) {
      pageIndices = [...Array(VISIBLE_PAGE_BUTTON_COUNT)]
        .map((_, index) => pageCount - (index + 1))
        .reverse();
      pageIndices.unshift(0);
      return (
        <PageList
          pageIndices={pageIndices}
          gotoPage={gotoPage}
          pageIndex={pageIndex}
          showFistPageJumping={true}
        />
      );
    }

    pageIndices = [...Array(VISIBLE_PAGE_BUTTON_COUNT)].map(
      (_, index) => pageIndex + index,
    );
    pageIndices.push(pageCount - 1);
    return (
      <PageList
        pageIndices={pageIndices}
        gotoPage={gotoPage}
        pageIndex={pageIndex}
        showLastPageJumping={true}
      />
    );
  }, [pageCount, pageIndex]);

  return (
    <div
      data-component="Pagination"
      className={twMerge('flex justify-center', className)}
    >
      <ul className="flex gap-2 p-0 m-0">
        <li className="list-none">
          <PageBtn
            content={<FaChevronLeft size="0.8rem" />}
            onClick={() => gotoPage(pageIndex - 1)}
            disabled={pageIndex <= 0}
          />
        </li>
        {renderPageLinks()}
        <li className="list-none">
          <PageBtn
            content={<FaChevronRight size="0.8rem" />}
            onClick={() => gotoPage(pageIndex + 1)}
            disabled={pageIndex + 1 >= pageCount}
          />
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
