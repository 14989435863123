import { SimpleUser } from 'models/user';

export enum Locales {
  VIETNAMESE = 'vi',
  ENGLISH = 'en',
}

export enum ErrorCodePage {
  NOT_FOUND = 404,
  ACCESS_DENIED = 403,
}

export enum StorageKeys {
  USER = 'userId',
}

export enum UserRole {
  TRIPPER = 'tripper',
  TRIP_MAKER = 'trip_maker',
  TRIP_DESIGNER = 'trip_designer',
  TOUR_OPERATOR = 'tour_operator',
  MODERATE = 'moderate',
  ADMIN = 'admin',
  SUPER_ADMIN = 'superadmin',
}

export const MEDIA_MIME_TYPES = {
  gif: 'image/gif',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  jfif: 'image/jpeg',
  png: 'image/png',
  svg: 'image/svg+xml',
  webp: 'image/webp',
  bmp: 'image/bmp',
};

export const supportedMedias = Object.keys(MEDIA_MIME_TYPES).map(
  (ext) => `.${ext}`,
);

export const DEFAULT_IMAGE_EXT = 'jpg';

export const MAX_UPLOAD_SIZE = 5 * 1024 * 1024;

export const NONE_AVAILABLE_TEXT = 'N/A';

export const DEFAULT_PAGE_SIZE_OPTIONS = ['10', '15', '20'];

export const DEFAULT_PAGE_NUMBER = 1;

export const DEFAULT_PAGE_SIZE = 10;

export enum STATE_TRIP {
  Pending = 'CHỜ DUYỆT',
  onSale = 'ĐANG BÁN',
  expired = 'HẾT HẠN',
  draft = 'NHÁP',
}

export enum DISPUTE_STATUS {
  opening = 'Chưa xử lý',
  pending = 'Đang xử lý',
  finished = 'Đã hoàn tiền',
  cancelled = 'Đã huỷ',
}

export enum DISPUTE_STATUS_PAYLOAD {
  opening = 'opening',
  pending = 'pending',
  finished = 'finished',
  cancelled = 'cancelled',
}

export enum DISPUTE_ACTIONS {
  cancel = 'Cancel',
  finished = 'Finish',
  suspend = 'Suspend', // it will be pending
}

export enum QNA_ACTIONS {
  delete = 'delete',
  reply = 'reply',
}

export enum AppCurrency {
  VND = 'vnd',
  USD = 'usd',
}

export enum ChatBoxType {
  MessageBox = '@chat/MessageBox',
  NotificationBox = '@chat/NotificationBox',
}
// VietNam location
export const DEFAULT_LOCATION = {
  lat: 10.7746806,
  lng: 106.6922504,
};

export const ZOOM_DEFAULT = 12;

export enum CheckpointNameType {
  Start = 'Xuất phát',
  End = 'Kết thúc',
}

export enum MoreActionType {
  DeleteCheckpoint = '@checkpoint/DeleteCheckpoint',
  UpdateCheckpoint = '@checkpoint/UpdateCheckpoint',
  AddCheckpoint = '@checkpoint/AddCheckpoint',
}

export enum NewsTypes {
  ALL = 'all',
  PRIVATE = 'private',
  PUBLIC = 'public',
  BLOG = 'blog',
  PROMOTE = 'promote',
  TERM_OF_USE = 'termOfUse',
  TERM_OF_PRIVACY = 'termOfPrivacy',
  TERM_OF_DISPUTE = 'termOfDispute',
  INVESTMENT = 'investment',
  RECRUIT = 'recruit',
  OTHERS = 'others',
}

export enum OrderStatus {
  REFUNDED = 'Đã hoàn tiền',
  PAID = 'Đã thanh toán',
  UNPAID = 'Chưa thanh toán',
  COMPLAINING = 'Đang khiếu nại',
}

export enum BuildURLType {
  TRIP_DETAIL = 'TRIP_DETAIL',
  MY_TRIP_DETAIL = 'MY_TRIP_DETAIL',
}

export const DIMENSION_DEFAULT = {
  width: 800,
  height: 800,
};

export const systemUser: SimpleUser = {
  displayName: 'Hệ thống',
  avatar: '/logo512.png',
  roles: [UserRole.SUPER_ADMIN],
} as SimpleUser;
