const env = {
  appEnv: process.env.REACT_APP_ENV,
  mapStyleUrl: process.env.REACT_APP_MAPLIBRE_STYLE_URL,
  apiUrl: process.env.REACT_APP_API_URL,
  socketUrl: process.env.REACT_APP_SOCKET_URL,
  imageDomain: process.env.REACT_APP_IMG_DOMAIN_URL,
  thumbImageDomain: process.env.REACT_APP_IMG_THUMB_DOMAIN_URL,
  generatePresignedUrl: process.env.REACT_APP_GENERATE_PRESIGNED_URL,
  searchServiceUrl: process.env.REACT_APP_SEARCH_SERVICE_URL,
  searchServiceApiKey: process.env.REACT_APP_SEARCH_SERVICE_API_KEY,
  routesServiceUrl: process.env.REACT_APP_ROUTE_SERVICE_URL,
  fallbackRoutesServiceUrl: process.env.REACT_APP_FALLBACK_ROUTE_SERVICE_URL,
  disputeExpireSec: process.env.REACT_APP_DISPUTE_EXPIRE_SEC,
  portalAppHost: process.env.REACT_APP_PORTAL_APP_HOST,
  shortenUrlTool: process.env.REACT_APP_SHORTEN_URL_SERVICE_URL,
  mapTool: process.env.REACT_APP_MAP_SERVICE_URL,
  translateAITool: process.env.REACT_APP_TRANSLATE_AI_SERVICE_URL,
  imageAITool: process.env.REACT_APP_IMAGE_AI_SERVICE_URL,
  outlineUrl:
    'https://tripical.vn/news/public/Outline-de-viet-lich-trinh-du-lich-hoan-hao-tai-Tripical-65451a24fa8b0ab13bf54b99',
};

export default env;
