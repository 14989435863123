import {
  configureStore,
  combineReducers,
  PayloadActionCreator,
} from '@reduxjs/toolkit';
import authSlice from 'stores/authSlice';
import checkpointSlice from 'sections/TripManagement/checkpointForm/slices';
import { resetAllSlice } from './global.action';
import themeSlice from 'stores/themeSlice';

const combinedReducer = combineReducers({
  auth: authSlice.reducer,
  theme: themeSlice.reducer,
  checkpoint: checkpointSlice.reducer,
});

const rootReducer = (state: any, action: PayloadActionCreator) => {
  if (action.type === resetAllSlice.type) {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['checkpoints/setViewMap'],
        // Ignore these field paths in all actions
        // ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: ['checkpoint.mapView.center'],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;

export default store;
